import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Dialog from '../components/dialog/dialog';
import LoadingScreen from '../components/loading/loading-screen';
import {DeskDto, UserDto} from '../interfaces/dto';
import useDesksService from '../services/use-desk-service';
import useLoginService, {LoginResult} from '../services/use-login-service';
import useUsersService from '../services/use-users-service';
import theme from '../theme';

interface IProps {
  onSuccess: (user: UserDto, desk?: DeskDto) => void;
}

const Root = styled.div`
  width: 100vw;
  height: 100vh;
  background: ${theme.color.darkGray};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: 100%;
  max-width: 500px;
  padding: 20px;

  h2{
    font-weight: lighter;
    font-size: 2.4em;
    text-align: center;
    margin-top:0;
  }
  input, button{
    box-sizing: border-box;
    height: 2.5em;
    flex-grow: 1;
    font-size: 1em;
    width: 50%;
  }
  input{
    padding-left: 10px;
    border:0;
  }
  button{
    margin: 20px auto 0 auto;
    color: ${theme.color.white};
    background: ${theme.color.darkCyan};
    box-shadow: 0px 0px 3px gray;
  }
  button:hover{
    background: ${theme.color.cyan};
  }
`;

const Row = styled.div`
  flex-shrink: 1;
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin: 5px 0 5px 0;
  box-shadow: 0px 0px 3px gray;

  div{
    flex-shrink: 0;
    color: ${theme.color.white};
    background: ${theme.color.darkCyan};
    width: 2.5em;
    height: 2.5em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const LoginPage: React.FC<IProps> = props => {

  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [dialog, setDialog] = useState<null | 'Incorrect'>();
  const [loginState, setLoginState] = useState<'Loading' | 'AutoIdle' | 'AutoSended' | 'OK' | 'Idle' | 'Sended'>('Loading');
  const {loginResult, login, logoutResult, logout} = useLoginService();

  const [users, setUsers] = useState<UserDto[]>([]);
  const {usersResult, loadUsers} = useUsersService();
  const [desks, setDesks] = useState<DeskDto[]>([]);
  const {desksResult, loadDesks} = useDesksService();

  const handleLogin = () => {
    if (!dialog && loginState === 'Idle' && loginResult.status !== 'Loading') {
      setLoginState('Sended');
      login(username, password);
    }
  };

  // Login
  useEffect(() => {
    const processLogin = (result: LoginResult): boolean => {
      if (result.success) {
        let user: UserDto | undefined;
        let desk: DeskDto | undefined;

        if (!result.userId) {
          user = users.find((u: UserDto) => u.username === username);
        }

        if (result.deskId) { // Have DeskId from BE
          desk = desks.find((d: DeskDto) => d.id === result.deskId);
        }

        if (user) {
          props.onSuccess(user, desk);
          return true;
        }
      }

      return false;
    };

    switch (loginState) {
      case 'Loading':
        if (usersResult.status === 'Idle') {
          loadUsers();
          loadDesks();
        } else if (usersResult.status === 'Ready' && desksResult.status === 'Ready') {
          setUsers(usersResult.payload.users.map(u => u.user));
          setDesks(desksResult.payload.desks.map(d => d.desk));
          setLoginState('AutoIdle');
        }
        break;

      case 'AutoIdle':
        if (logoutResult.status === 'Idle') {
          logout(); // Auto logout
          setLoginState('AutoSended');
        }
        break;

      case 'AutoSended':
        if (logoutResult.status === 'Ready') {
          setLoginState('Idle');
        }
        break;

      case 'Sended':
        if (loginResult.status === 'Ready') {
          if (loginResult.payload.success && processLogin(loginResult.payload)) {
            setLoginState('OK');
          } else {
            setLoginState('Idle');
            setDialog('Incorrect');
          }
        }
        break;
    }
  }, [logoutResult, logout, loginResult, login, setDialog, loginState, usersResult, loadUsers, desksResult, loadDesks, desks, props, username, users]);

  return (
    <>
      {loginState === 'Idle' ? (
        <Root>
          <Form onSubmit={(e: any) => {handleLogin(); e.preventDefault(); }}>
            <h2>QPadPro</h2>
            <Row>
              <div>
                <FontAwesomeIcon icon={['fas', 'user']} size='1x' />
              </div>
              <input id='username' type='text' disabled={loginResult.status === 'Loading'} placeholder='Uživatelské jméno' autoFocus={true} value={username} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)}/>
            </Row>
            <Row>
              <div>
                <FontAwesomeIcon icon={['fas', 'lock']} size='1x' />
              </div>
              <input id='password' type='password' disabled={loginResult.status === 'Loading'} placeholder='Heslo' value={password} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}/>
            </Row>

            <button disabled={loginResult.status === 'Loading'} type={'submit'}>PŘIHLÁŠENÍ</button>
          </Form>
        </Root>
      ) : (
        <LoadingScreen/>
      )}

      <Dialog open={dialog === 'Incorrect'} onCloseClick={() => setDialog(null)}>
        <h2>Nesprávné jméno nebo heslo</h2>
      </Dialog>
    </>
  );
};

export default LoginPage;
