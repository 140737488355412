import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import eventManager from '../../event-manager';
import {QBus} from '../../qbus';
import theme from '../../theme';

interface Iprops {
  value: boolean;
  labelOn?: string;
  labelOff?: string;
  onToggle?: (value: boolean) => void;
  action?: {
    qbus: QBus;
    on: string;
    off: string;
  };
}

const ToggleButton = styled.button`
  font-size: 1.6em;
  background: none;
  border: none;
  margin-left: 24px;

  &:hover {
    background: #cfeff8;
  }
`;

const Icon = styled.span`
  margin-right: 16px;
  color: ${theme.color.red};
  transition: 0.15s;
  &.checked{
    color: ${theme.color.green};
  }
`;

const Toggle: React.FC<Iprops> = props => {
  const [isChecked, setBarState] = useState<boolean>(props.value);

  const toggle = (event: Event) => {
    const value = !isChecked;
    setBarState(value);
    if (props.onToggle) {
      props.onToggle(value);
    }
  };

  // logic for actions
  useEffect(() => {
    if (props.action) {
      const eventOn = props.action.on;
      const eventOff = props.action.off;

      const listenerOn = () => {
        setBarState(true);
        props.onToggle?.(true);
      };
      const listenerOff = () => {
        setBarState(false);
        props.onToggle?.(false);
      };

      if (eventOn) {
        eventManager.addListener(eventOn, listenerOn);
      }
      if (eventOff) {
        eventManager.addListener(eventOff, listenerOff);
      }

      return () => {
        if (eventOn) {
          eventManager.removeListener(eventOn, listenerOn);
        }
        if (eventOff) {
          eventManager.removeListener(eventOff, listenerOff);
        }
      };
    }
  }, [props]);

  function getLabelText() {
    if (isChecked) {
      if (props.labelOn !== undefined) {
        return props.labelOn;
      }
    } else {
      if (props.labelOff !== undefined) {
        return props.labelOff;
      }
    }

    return props.children;
  }

  function selectIcon(): IconProp {
    return ['fas', isChecked ? 'toggle-on' : 'toggle-off'];
  }

  return (
    <ToggleButton onClick={toggle}>
      <Icon className={isChecked ? 'checked' : ''}>
        <FontAwesomeIcon icon={selectIcon()} />
      </Icon>
      <span>{getLabelText()}</span>
    </ToggleButton>
  );

};

export default Toggle;