import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import styled from 'styled-components';
import {v4 as uuid} from 'uuid';
import allActions from '../actions';
import Button from '../components/button/button';
import {DialogBody, DialogFooter, DialogHeader, DialogWindow} from '../components/dialog/dialog';
import LoadingScreen from '../components/loading/loading-screen';
import Overlay from '../components/overlay';
import DeskSelector from '../components/select/desk-selector';
import {DeskDto} from '../interfaces/dto';
import theme from '../theme';

interface IProps {
  desk: DeskDto | undefined;
  onSubmit(desk: DeskDto, qmpadId: string): void;
}

const Root = styled.div`
  width: 100vw;
  height: 100vh;
  background: ${theme.color.darkGray};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  min-width: 300px;
  *{
    box-sizing:border-box;
  }
  button{
    box-sizing:border-box;
    padding: 5px;
  }
  h3{
    margin: 10px 0 0 0;
    font-size: .8em;
    font-weight: 600;
  }
  select, textarea{
    box-sizing: border-box;
    width: 100%;
  }
  option{
    padding: 5px;
  }
`;

const DeskSelectPage: React.FC<IProps> = (props: React.PropsWithChildren<IProps>) => {

  const [selectedDesk, setSelectedDesk] = useState<DeskDto | undefined>(props.desk);
  const [loading, setLoading] = useState<boolean>(false);
  const [qmpadId, setQmpadId] = useState<string>('');
  const dispatch = useDispatch();
  const [loadingState, setLoadingState] = useState<'Init' | 'WaitingForDeskSelect' | 'Qmpad' | 'LogOpen' | 'SetActiveServices' | 'OK' >('Init');

  const sumbit = () => {
    if (selectedDesk) {
      setLoading(true);
      setLoadingState('Qmpad');
    }
  };

  useEffect(() => {
    switch (loadingState) {
      case 'Init':
        if (props.desk) {
          setSelectedDesk(props.desk);
          setLoadingState('Qmpad');
        } else {
          setLoadingState('WaitingForDeskSelect');
        }
        break;

      case 'Qmpad':
        setQmpadId(uuid());
        setLoadingState('SetActiveServices');
        break;

      case 'SetActiveServices':
        if (selectedDesk) {
          // Everything OK, Dispatch selected Desk/Services and submit
          const services = selectedDesk.services.map(i => i.id);
          selectedDesk.services.sort((a, b) => a.tag.localeCompare(b.tag));
          dispatch(allActions.desks.setCurrentDesk(selectedDesk));
          dispatch(allActions.services.setDeskId(selectedDesk.id));
          dispatch(allActions.services.selectServices(services));
          props.onSubmit(selectedDesk, qmpadId);
          setLoadingState('OK');
        }
        break;
    }
  }, [loading, selectedDesk, qmpadId, dispatch, props, loadingState]);

  return (
    <Root>
      {(loadingState === 'WaitingForDeskSelect') ? (
        <Overlay open={true} background={theme.color.darkGray}>
          <DialogWindow>
            <DialogHeader>Vyberte přepážku:</DialogHeader>
            <DialogBody>
              <Content>
                <DeskSelector size={8} onChange={(desk: DeskDto) => setSelectedDesk(desk)}/>
              </Content>
              <DialogBody>
              <DialogFooter>
                <div></div>
                <Button disabled={!selectedDesk} click={sumbit}>POKRAČOVAT</Button>
              </DialogFooter>
              </DialogBody>
            </DialogBody>
          </DialogWindow>
        </Overlay>
      ) : (
        <LoadingScreen/>
      )}
    </Root>
  );
};

export default DeskSelectPage;
