import defaultConfig from '../config/default-config';
import {ConfigDto} from '../interfaces/dto';

interface SetConfig {
  type: 'SET_CONFIG';
  payload: ConfigDto;
}

type ConfigAction = SetConfig;

export interface ConfigState {
  initialized: boolean;
  current: ConfigDto;
}

const initialState = {
  initialized: false,
  current: defaultConfig
};

const config = (state: ConfigState = initialState, action: ConfigAction) => {
  switch (action.type) {
    case 'SET_CONFIG': return {...state, current: action.payload, initialized: true};
    default: return state;
  }
};

export default config;
