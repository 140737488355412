import {RefObject} from 'react';
import {useDispatch} from 'react-redux';
import Websocket from 'react-websocket';
import allActions from './actions';
import eventManager, {EventType} from './event-manager';
import {TicketDto, TicketsList} from './interfaces/dto';
import {sendNotification} from './ipc-communicator';

export class QBus {

  private registered = false;
  private dispatch = useDispatch();
  private userId: string;
  private events = [
    'QMCALLTICKETRESPONSE',
    'QMCANCELTICKETRESPONSE',
    'QMPAUSETICKETRESPONSE',
    'QMWAITINGLISTRESPONSE',
    'QMWAITINGCOUNTRESPONSE',
    'QMCANCELLEDLISTRESPONSE',
    'QMPAUSEDLISTRESPONSE',
    'QMCALLCANCELLEDTICKETRESPONSE',
    'QMCALLPAUSEDTICKETRESPONSE',
    'QMENDCALLRESPONSE',
    'QMTRANSFERTICKETRESPONSE',
    'QMRECALLTICKETRESPONSE',
    'QMCALLONETICKETRESPONSE',
    'QMCALLONETICKETHIDERESPONSE',
    'QMDEVICESSTATUS',
    'QMALERTMESSAGE',
    'QMPADPING',
    'QMPADLOCK',
    'QMRESTARTALL',
    'QMRELOADFORMCHANGES',
    'QMTICKETRESET',
    'QMRESETTICKET',
    'QMALERTCENTERNOTIFICATION',
    'BLOOMDEVICECONFIG',
    'QMRESTARTQMPAD',
    'QMOPENSERVICE',
    'QMCLOSESERVICE',
    'QMSERVICESTATERESPONSE',
    'QMRESTARTCALLINGDEVICES',
  ];

  private get qmpadId() {
    return 'qmpad_' + this.id;
  }

  get id() {
    return this._id;
  }

  constructor(private webSocketRef: RefObject<Websocket>, private _id: string, userId: string, private deskId: string, private startingTicket?: TicketDto) {
    this.userId = userId;
    // tslint:disable-next-line:no-console
    console.log('Qbus registered. ID: ' + _id);
  }

  register() {
    this.send('REGISTER', [
      'Type: qmpad', `Id: ${this.qmpadId}`, `Register-To: ${this.events.join(';')}`
    ]);

  }

  private send(frame: string, headers: string[], data?: string) {
    if (!data) data = '';
    this.webSocketRef.current.sendMessage([
      frame,
      ...headers,
      `Content-Length: ${data ? Buffer.byteLength(data) : 0}`,
      '',
      data
    ].join('\r\n'));
  }

  private request(event: string, params: string[]) {
    this.send('EVENT', [
      `Event: ${event}`, `Id: ${this.qmpadId}`],
      '<request>'
      + `<qmpad_id>${this.qmpadId}</qmpad_id>`
      + '<params>'
      + params.map(param => `<param>${param}</param>`).join('')
      + '</params>'
      + '</request>'
    );
  }

  getTickets(serviceId: string, filter: TicketsList) {
    let event;
    switch (filter) {
      case 'waiting': event = 'QmWaitingList'; break;
      case 'canceled': event = 'QmCancelledList'; break;
      case 'paused': event = 'QmPausedList'; break;
    }
    this.request(event, [serviceId]);
  }

  // TODO: Printing feature
  /*
  sendPrintTicket(service: ServiceDto, printer: PrinterDto) {
    const now = new Date();
    const date = now.getFullYear() + '-'
      + ('' + (now.getMonth() + 1)).padStart(2, '0') + '-'
      + ('' + now.getDate()).padStart(2, '0') + ' '
      + ('' + now.getHours()).padStart(2, '0') + ':'
      + ('' + now.getMinutes()).padStart(2, '0') + ':'
      + ('' + now.getSeconds()).padStart(2, '0');
    const payload =
      '<ticket_dispenser>' +
        `<ip>${printer.ip}</ip>` +
        '<port></port>' +
        '<printer_port></printer_port>' +
        '<ticket>' +
          '<waiting_time>00:00:00</waiting_time>' +
          `<number_of_waiting_tickets>${service.quantity}</number_of_waiting_tickets>` +
          `<date>${date}</date>` +
          `<letter>${service.tag}</letter>` +
          `<number>${service.quantity + 2}</number>` +
          `<service>${service.id}</service>` +
          '<lang></lang>' +
          '<flags>0</flags>' +
        '</ticket>' +
      '</ticket_dispenser>';
    this.send('EVENT', [`Event: qmprintticket`, `Id: ${this.qmpadId}`], payload);
  }
  */

  sendCallTicket(deskId: string, servicesIds: string[]) {
    this.request('QmCallTicket', [
      deskId,
      this.userId,
      `"${servicesIds.join('","')}"`
    ]);
  }

  sendTransferTicket(answerId: string, destinationType: 'Service' | 'User' | 'Desk', destinationId: string, note: string, priority: boolean) {

    this.request('QmTransferTicket' + destinationType, [
      answerId,
      destinationId,
      note,
      priority.toString()
    ]);
  }

  sendCallOneTicket(ticketId: string, deskId : string) {
    this.request('QmCallOneTicket', [
      ticketId,
      deskId,
      this.userId,
    ]);
  }

  sendCallCancelledTicket(ticketId: string, deskId : string) {
    this.request('QmCallCancelledTicket', [
      ticketId,
      deskId,
      this.userId,
    ]);
  }

  sendCallPausedTicket(ticketId: string, deskId : string) {
    this.request('QmCallPausedTicket', [
      ticketId,
      deskId,
      this.userId,
    ]);
  }

  recieve(data: string) {
    if (!this.registered) {
      this.registered = (data.startsWith('HTTP/1.1 200 OK'));
      if (this.startingTicket) {
        this.sendRecallTicket(this.startingTicket);
      }
      this.sendSetDeskLEDServiceBusy(this.deskId, false);

      // use Id of service from tickets.source reducer
      this.request('QmWaitingCount', []);
      this.request('QmWaitingList', ['a555756b-f899-4b25-bf1f-a197f6ec4a22']);
      return;
    }

    const endOfHeaders = data.indexOf('\r\n\r\n');
    const headers = data.substr(0, endOfHeaders).split('\r\n').map(
      entry => entry.split(':')
    );
    if (!headers.shift()?.includes('Event')) return;

    const content = data.substr(endOfHeaders + 4);
    const eventHeader = headers.find(h => (h[0] === 'Event'));
    if (!eventHeader) return;
    const event = eventHeader[1].toLowerCase();

    if (content.length > 0) {
      const parser = new DOMParser();
      switch (event) {
        case 'qmalertcenternotification':
          this.sendNotification(content);
          break;

        default:
          this.parseMessage(event, parser.parseFromString(content, 'text/xml'));
      }

    } else {
      switch (event) {
        case 'qmpadping': this.pong(); break;
        case 'qmdevicesstatus': this.sendDeviceStatus(); break;
        case 'qmrestartall':
          this.dispatch(allActions.desks.updateCurrentDesk(new Date().getTime()));
          this.request('QmWaitingCount', []); // refresh services
          break;
        default:
          // tslint:disable-next-line:no-console
          console.warn('unhandled event', event);
      }
    }
  }

  private parseMessage(event: string, payload: Document) {
    const idNode = payload.getElementsByTagName('qmpad_id');
    if (!idNode || idNode.length === 0) return;
    const qPadId = idNode[0].childNodes[0]?.nodeValue;
    if (qPadId && qPadId !== 'ALL' && qPadId !== '' && qPadId !== this.qmpadId) return;

    const messageNode = payload.getElementsByTagName('message');
    const message = messageNode[0]?.childNodes[0]?.nodeValue;
    if (!message) return;

    switch (event) {
      case 'qmwaitingcountresponse':
        this.setServices(message);
        break;

      case 'qmwaitinglistresponse':
      case 'qmcancelledlistresponse':
      case 'qmpausedlistresponse':
        this.setTickets(message);
        break;

      case 'qmcallticketresponse':
      case 'qmcalloneticketresponse':
      case 'qmcallcancelledticketresponse':
      case 'qmcallpausedticketresponse':
      case 'qmrecallticketresponse':
        if (this.takeTicket(message)) {
          eventManager.invokeEvent(EventType.takeTicket);
        }
        break;

      case 'qmpauseticketresponse':
        if (this.closeTicket(message)) {
          eventManager.invokeEvent(EventType.pauseTicket);
        }
        break;

      case 'qmcancelticketresponse':
        if (this.closeTicket(message)) {
          eventManager.invokeEvent(EventType.cancelTicket);
        }
        break;

      case 'qmendcallresponse':
        if (this.closeTicket(message)) {
          eventManager.invokeEvent(EventType.closeTicket);
        }
        break;

      case 'qmtransferticketresponse':
        if (this.closeTicket(message)) {
          eventManager.invokeEvent(EventType.transferTicket);
        }
        break;

      default:
        // tslint:disable-next-line:no-console
        console.warn('unhandled event', event, message);
    }
  }

  private setServices(message: string) {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(message, 'text/html');
    if (!xmlDoc) return;
    const services = Array.from(xmlDoc.getElementsByTagName('service')).map(
      service => ({
        id: service.getAttribute('id') || '',
        quantity: +(service.getAttribute('quantity') || 0),
        tag: service.getAttribute('tag') || '',
        state: true,
      })
    );

    // TODO: Notifications feature
    // this.scanForNotifications(services);
    this.dispatch(allActions.services.setServices(services));
  }

  /*
  private scanForNotifications(services: ServiceDto[]) {
    const storedServices = store.getState().services.list.filter(i => store.getState().services.selected?.includes(i.id) || false);
    const deskServices = store.getState().desks.current?.services;

    services.forEach(service => {
      const storeService = storedServices.find(i => i.id === service.id);
      if (storeService && storeService.quantity < service.quantity) {
        let title = service.tag + ' - Nový lístek';
        let body = '';
        if (deskServices) {
          body += deskServices.find(i => i.id === storeService.id)?.description || '';
        }
        sendNotification(title, body);
      }
    });
  }
  */

  private setTickets(message: string) {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(message, 'text/html');
    if (!xmlDoc) return;
    const tickets = Array.from(xmlDoc.getElementsByTagName('ticket')).map(
      node => (
        this.parseTicket(node, 'attributes')
      )
    );
    this.dispatch(allActions.tickets.setTickets(tickets));
  }

  private parseTicket(node: Element, dataIn: 'attributes' | 'tags'): TicketDto {
    const flags = +(this.xmlParse(node, 'flags', dataIn) || 0);
    const service_id = this.xmlParse(node, 'service_id', dataIn) || this.xmlParse(node, 'ticket_service_id', dataIn) || '';
    const dst_service_id = this.xmlParse(node, 'dst_service_id', dataIn) || '';
    const dst_desk_id = this.xmlParse(node, 'dst_desk_id', dataIn) || '';
    const transfered = (this.xmlParse(node, 'transferred', dataIn) === '1') || (service_id !== dst_service_id) || ((flags & (1 << 10)) !== 0);

    return {
      id: this.xmlParse(node, 'id', dataIn) || '',
      number: +(this.xmlParse(node, 'number', dataIn) || 0),
      date: this.xmlParse(node, 'date', dataIn) || '',
      flags,
      service_id,
      dst_service_id,
      dst_desk_id,
      dst_user_id: this.xmlParse(node, 'dst_user_id', dataIn) || '',
      answer_id: this.xmlParse(node, 'answer_id', dataIn) || '',
      call_date: this.xmlParse(node, 'call_date', dataIn) || '',
      waiting_time: this.xmlParse(node, 'waiting_time', dataIn) || '',
      note: (this.xmlParse(node, 'note', dataIn) || '').trim(),
      transferred: transfered,
    };
  }

  private xmlParse(node: Element, name: string, dataIn: 'attributes' | 'tags'): string | null
  {
    let value : string | null = null;
    switch (dataIn) {
      case 'attributes':
        value = node.getAttribute(name);
        break;

      case 'tags':
        if (node.getElementsByTagName(name)[0] && node.getElementsByTagName(name)[0].childNodes[0]) {
          value = node.getElementsByTagName(name)[0].childNodes[0].nodeValue;
        }
        break;
  }
  return (value) ? value.trim() : value;
}

  private takeTicket(message: string): boolean {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(message, 'text/html');
    if (!xmlDoc) return false;
    const idNode = xmlDoc.getElementsByTagName('id');
    if (!idNode.length) {
      // empty queue
      this.dispatch(allActions.tickets.takeTicket(null));
      return false;
    }

    const ticket: TicketDto = this.parseTicket(xmlDoc.getElementsByTagName('ticket')[0], 'tags');
    this.dispatch(allActions.tickets.takeTicket(ticket));
    return true;
  }

  private closeTicket(message: string) {
    if (message === 'OK') {
      this.dispatch(allActions.tickets.closeTicket());
      return true;
    }
    return false;
  }

  private pong() {
    this.send('Event', ['Event: QmPadPong', `Id: ${this.qmpadId}`], this.qmpadId);
  }

  private sendDeviceStatus() {
    this.send('EVENT', ['Event: QmDevicesStatusResponse', `Id: ${this.qmpadId}`],
      '<devices type="qmpad">' +
        '<device>' +
        `<id>${this.qmpadId}</id>` +
        '<status>0</status>' +
      '</device>' +
      '</devices>');
  }

  private sendNotification(content: string) {
    const message = JSON.parse(content);
    if (message.users.find(userId => (userId === this.userId))) {
      let title = 'QPadPro';
      let body: string = message.text;

      if (body.startsWith('Nový lístek pro:')) {
        title = 'Nový lístek';
        body = body.split(':', 2)[1];
      }
      if (body[body.length - 1] === '.') {
        body = body.substr(0, body.length - 1);
      }
      body = body.trim();

      sendNotification(title, body);
    }
  }

  sendCloseTicket(ticket: TicketDto, note = '') {
    if (!ticket) return;
    this.request('QmEndCall', [`${ticket.answer_id}`, note]);
  }

  sendCancelTicket(ticket: TicketDto, note = '') {
    if (!ticket) return;
    this.request('QmCancelTicket', [`${ticket.answer_id}`, note]);
  }

  sendRecallTicket(ticket: TicketDto) {
    if (!ticket) return;
    this.request('QmRecallTicket', [`${ticket.answer_id}`]);
  }

  sendPauseTicket(ticket: TicketDto) {
    if (!ticket) return;
    this.request('QmPauseTicket', [`${ticket.answer_id}`]);
  }

  sendSetDeskLEDServiceBusy(deskId: string, action: boolean) {
    const event = 'LEDServiceSetCounterBusy';
    this.send('EVENT', [
      `Event: ${event}`, `Id: ${this.qmpadId}`],
      `{"desk_id":"${deskId}","action":${action}}`
    );
  }
}
