import React from 'react';
import {useSelector} from 'react-redux';
import {ConfigDto, ToggleDto} from '../../interfaces/dto';
import {toggle as ipcToggle} from '../../ipc-communicator';
import {QBus} from '../../qbus';
import Toggle from '../toggle/toggle';

interface IProps {
  qbus: QBus;
}

const ControlToggles: React.FC<IProps> = (props: IProps) => {
  const config: ConfigDto | null = useSelector(state => state.config.current);

  return (
    <>
      {config && config.toggles.map((toggle: ToggleDto, index: number) => (
        <Toggle
          key={index}
          value={true}
          action={{
            qbus: props.qbus,
            on: toggle.action.on,
            off: toggle.action.off
          }}
          labelOn={toggle.show.on}
          labelOff={toggle.show.off}
          onToggle={(value: boolean) => {
            ipcToggle(toggle.device, value, value ? toggle.payload.on : toggle.payload.off);
          }} />
      ))}
    </>
  );
};

export default ControlToggles;