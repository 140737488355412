import {DeskDto} from '../interfaces/dto';

const setCurrentDesk = (desk: DeskDto) => {
  return {
    type: 'SET_CURRENT_DESK',
    payload: desk,
  };
};

const updateCurrentDesk = (timestamp: number) => {
  return {
    type: 'UPDATE_CURRENT_DESK',
    payload: timestamp,
  };
};

const setDesks = (list: DeskDto[]) => {
  return {
    type: 'SET_DESKS',
    payload: list,
  };
};

export default {
  setCurrentDesk, updateCurrentDesk, setDesks
};
