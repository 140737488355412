import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {ServiceDto} from '../../interfaces/dto';
import {ServicesState} from '../../reducers/services';
import useServicesService, {ServiceWrapper} from '../../services/use-services-service';

interface IProps {
  onChange?: (service: ServiceDto) => void;
  size?: number;
}

const ServiceSelector: React.FC<IProps> = props => {
  const {servicesResult, loadServices} = useServicesService(); // used for description
  const services: ServicesState = useSelector(state => state.services);

  useEffect(() => {
    if (servicesResult.status === 'Idle') {
      loadServices();
    }
  }, [servicesResult, loadServices]);

  const selectChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (props.onChange) {
      if (servicesResult.status === 'Ready') {
        const selected = servicesResult.payload.services.find((i: ServiceWrapper) => i.service.id === event.target.value)?.service;
        if (selected) {
          props.onChange(selected);
        }
      }
    }
  };

  const getQuantity = (id: string) => {
    return (services && services.list && services.list.find((s: ServiceDto) => s.id === id)?.quantity);
  };

  return (
    <select onChange={selectChanged} size={props.size}>
      {
        servicesResult.status === 'Ready' && servicesResult.payload.services.map(i => (
          <option key={i.service.id} value={i.service.id}>
            {`${i.service.tag}: ${i.service.description} (${getQuantity(i.service.id) || 0})`}
          </option>
        ))
      }
    </select>
  );
};

export default ServiceSelector;
