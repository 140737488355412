const theme = {
    color: {
        white: 'white',
        black: 'black',
        lightGray: '#e4e8ed',
        darkGray: '#d2dae2',
        cyan: '#4bcffa',
        lightCyan: '#effafd',
        darkCyan: '#058cc2',
        green: '#05c46b',
        red: '#db242a'
    },
    spacing: {
        x4: '4px',
        x8: '8px',
        x16: '16px'
    },
    transition: {
      fast: '.2s ease-in-out'
    }
};

export default theme;