import {DeskDto} from '../interfaces/dto';

interface SetCurrentDesk {
  type: 'SET_CURRENT_DESK';
  payload: DeskDto;
}

interface UpdateCurrentDesk {
  type: 'UPDATE_CURRENT_DESK';
  payload: number;
}

interface SetDesks {
  type: 'SET_DESKS';
  payload: DeskDto[];
}

type DeskAction = SetCurrentDesk | UpdateCurrentDesk | SetDesks;

export interface DeskState {
  list: DeskDto[];
  current: null | DeskDto;
  lastUpdate: number;
}

const initialState = {
  list: [],
  current: null,
  lastUpdate: 0,
};

const desks = (state: DeskState = initialState, action: DeskAction) => {
  switch (action.type) {
    case 'SET_DESKS': return {...state, list: action.payload};
    case 'SET_CURRENT_DESK': return {...state, current: action.payload};
    case 'UPDATE_CURRENT_DESK': return {...state, lastUpdate: action.payload};
    default: return state;
  }
};

export default desks;
