import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import theme from '../../theme';
import Overlay from '../overlay';
import LoadingIndicator from './loading-indicator';

const LoadingScreen: React.FC = () => {

  return (
    <Overlay open={true} background={theme.color.darkGray}>
      <LoadingIndicator>
        <FontAwesomeIcon icon={['fas', 'sync-alt']} size='5x' />
      </LoadingIndicator>
    </Overlay>
  );
};

export default LoadingScreen;