export {};

declare global {
   interface Date {
      toUTCTimeString(): string;
   }
}

// eslint-disable-next-line no-extend-native
Date.prototype.toUTCTimeString = function() : string {
   const hours = this.getUTCHours().toString().padStart(2, '0');
   const minutes = this.getUTCMinutes().toString().padStart(2, '0');
   const seconds = this.getUTCSeconds().toString().padStart(2, '0');
   return `${hours}:${minutes}:${seconds}`;
};