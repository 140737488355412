import {useState} from 'react';
import {Response} from '../interfaces/api';
import {ConnectionDto, DeskDto} from '../interfaces/dto';
import store from '../store';
import {cancelableFetch, IFetchData} from './service-processer';

export interface DeskWrapper {
  desk: DeskDto;
}

export interface Desks {
  success: boolean;
  desks: DeskWrapper[];
}

const useDeskService = () => {
  const [desksResult, setResult] = useState<Response<Desks>>({
    status: 'Idle'
  });

  const loadDesks = () => {
    const bloom: ConnectionDto = store.getState().config.current.bloom;
    const fetchData: IFetchData = {
      uri: `http://${bloom.host}:${bloom.port}/api/1/json/desks`,
      method: 'GET',
      parse: 'json'
    };

    return cancelableFetch(fetchData, setResult);
  };

  return {desksResult, loadDesks, setResult};
};

export default useDeskService;
