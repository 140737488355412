import React from 'react';
import styled from 'styled-components';
import {ServiceDto} from '../interfaces/dto';
import theme from '../theme';

const Queue = styled.div`
  display: flex;
  margin: 4px;
  font-size: 1.4em;
  align-items: stretch;
  label {
    height: 48px;
    width: 48px;
    line-height: 48px;
    text-align: center;
    background: ${theme.color.lightCyan};
    margin-right: 2px;
  }
`;

const Button = styled.button`
  width: 48px;
  height: 48px;
  margin: 0;
  &.selected {
    background: ${theme.color.cyan};
    font-weight: bold;
  }
`;

interface IProps {
  service: ServiceDto;
  onClick?: (service: ServiceDto) => void;
  selected: boolean;
}

const ServiceButton: React.FC<IProps> = props => {

  const clickEvent = (event: Event) => {
    props.onClick?.(props.service);
  };

  return (
    <Queue>
      <Button className={props.selected ? 'selected' : ''} onClick={clickEvent}>{props.service.tag}:{props.service.quantity}</Button>
    </Queue>
  );
};

export default ServiceButton;