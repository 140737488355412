import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import Button from '../components/button/button';
import {ServiceDto, TicketDto, TicketsList} from '../interfaces/dto';
import {QBus} from '../qbus';
import useTicketDetailsService from '../services/use-ticket-detail-service';
import theme from '../theme';
import SelectServicesDialog from './dialog/select-services-dialog/select-services-dialog';
import ServiceButton from './service-button';
import Ticket from './ticket';

const ServiceButtonsContainer = styled.div`
  width: 100vw;
  display: flex;
  box-sizing: border-box;
  flex-grow: 1;
  flex-wrap: wrap;
  padding: 5px;
  align-items: center;
`;

const QueueList = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  width: 100%;
  max-height: 100vh;
  background-color: ${theme.color.white};
  padding: 0;
  overflow: hidden;
  transition: top .3s ease-in-out;
`;

const StateButton = styled.button`
  font-size: 1.6em;
  border: none;
  height: 10vh;
  &:hover {
    background: ${theme.color.cyan};
  }
  &.active {
    background: ${theme.color.cyan};
  }
`;

const ServicesDialogButton = styled(Button)`
  width: 48px;
  height: 48px;
  margin: 4px;
`;

const TableControl = styled.div`
  display: flex;
  justify-content: space-equaly;
  margin: 0 12px;
  button {
    margin: 24px 12px;
    flex: 1 0 0;
  }
`;

const Table = styled.div`
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 0;
  transition: height .3s ease-in-out;
  &.open {
    height: 100vh;
  }
`;

const TicketsColumn = styled.div`
  flex-grow: 1;
`;

interface IProps {
  qbus: QBus;
}

const QueueBar: React.FC<IProps> = props => {
  const [listService, setListService] = useState<ServiceDto|null>(null);
  const [listType, setListType] = useState<TicketsList>('waiting');
  const [barState, setBarState] = useState<string>('closed');
  const tickets = useSelector(state => state.tickets); // see reducers/tickets.ts and actions/tickets-actions.ts
  const services = useSelector(state => state.services); // see reducers/services.ts and actions/services-actions.ts
  const deskId = useSelector(state => state.services.deskId);
  const {ticketDetailsResult, loadTicketDetails} = useTicketDetailsService();
  const [ticketDetails, setTicketDetails] = useState<TicketDto | null>(null);
  const [openServiceDialog, setOpenServiceDialog] = useState<boolean>(false);

  const closeBar = () => {
    setBarState('closed');
    setListType('waiting');
    setListService(null);
    setTicketDetails(null);
  };

  useEffect(() => {
    if (ticketDetailsResult.status === 'Ready') {
      setTicketDetails(ticketDetailsResult.payload);
    }
  }, [ticketDetailsResult]);

  const ticketExpanded = ticketId => {
    if (ticketDetails && ticketDetails.id === ticketId) {
      setTicketDetails(null);
    }
    else {
      loadTicketDetails(ticketId);
    }
  };

  const takeTicket = ticketId => {
    switch (listType) {
      case 'waiting': props.qbus.sendCallOneTicket(ticketId, deskId); break;
      case 'paused': props.qbus.sendCallPausedTicket(ticketId, deskId); break;
      case 'canceled': props.qbus.sendCallCancelledTicket(ticketId, deskId); break;
    }
    closeBar();
  };

  const setWaiting = (event: Event) => {
    setListType('waiting');
    if (listService) props.qbus.getTickets(listService.id, 'waiting');
  };

  const setCanceled = (event: Event) => {
    setListType('canceled');
    if (listService) props.qbus.getTickets(deskId, 'canceled');
  };

  const setPaused = (event: Event) => {
    setListType('paused');
    if (listService) props.qbus.getTickets(deskId, 'paused');
  };

  const onServiceSelect = (service: ServiceDto) => {
    if (barState === 'closed' || listService !== service) {
      setListService(service);
      setListType('waiting');
      props.qbus.getTickets(service.id, 'waiting');
      setBarState('open');
    } else {
      closeBar();
    }
  };

  return (
    <QueueList className={barState}>
        <ServiceButtonsContainer>
          <ServicesDialogButton icon='ellipsis-h' click={() => setOpenServiceDialog(true)}/>
          <SelectServicesDialog open={openServiceDialog} onCloseClick={() => setOpenServiceDialog(false)}/>

          { services.list.map((i: ServiceDto) => (
            services.selected.includes(i.id) && (
              <ServiceButton key={i.id} service={i} selected={listService?.id === i.id} onClick={onServiceSelect}/>
            )
          ))
        }
        </ServiceButtonsContainer>

      <Table className={barState}>
      <TableControl>
        <StateButton className={listType === 'waiting' ? 'active' : ''} onClick={setWaiting}>Čekající</StateButton>
        <StateButton className={listType === 'canceled' ? 'active' : ''} onClick={setCanceled}>Zrušené</StateButton>
        <StateButton className={listType === 'paused' ? 'active' : ''} onClick={setPaused}>Pozastavené</StateButton>
      </TableControl>

        <TicketsColumn>
        { tickets.list.map(i => (
          <Ticket
            key={i.id}
            data={i}
            services={services.list}
            detail={ticketDetails}
            onExpand={ticketId => ticketExpanded(ticketId)}
            onTakeClick={ticketId => takeTicket(ticketId)}
            >
          </Ticket>))}
        </TicketsColumn>
      </Table>
    </QueueList>
  );
};

export default QueueBar;
