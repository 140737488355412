import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import useLogSuspendService from '../../services/use-log-suspend-service';
import ButtonBase from '../button/button';
import Overlay from '../overlay';
import TabContent from '../tab';
import {DialogBody} from './dialog';

interface IProps {
  onCloseClick?(): void;
  open: boolean;
  qmpadId: string;
}

const Content = styled.div`
  font-size: 1.5em;
  min-width: 250px;
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  *{
    box-sizing:border-box;
  }
  button{
    margin-top: 20px;
    padding: 5px;
  }
  h2{
    margin: 0 0 30px 0;
    text-shadow: 1px 1px 3px black;
  }
  h3{
    margin: 0;
    font-size: .6em;
    font-weight: 600;
    text-shadow: 1px 1px 3px black;
  }
  select, input{
    width: 100%;
    margin: 5px 0 5px 0;
    padding: 5px;
  }
  option{
    padding: 5px;
  }
`;

const Button = styled(ButtonBase)`
  display:flex;
  align-items: center;
`;

const PauseDeskDialog: React.FC<IProps> = (props: React.PropsWithChildren<IProps>) => {
  const [selectedReason, setSelectedReason] = useState<string>('none');
  const [note, setNote] = useState<string>('');
  const [pauseState, setPauseState] = useState<'ChooseReason' | 'Paused' | 'Unpausing' | 'Unpaused'>('ChooseReason');
  const {logSuspendResult, logSuspend, logSuspendReasonResult, logSuspendReason} = useLogSuspendService();

  useEffect(() => {
    switch (pauseState) {
      case 'ChooseReason':
        if (logSuspendResult.status === 'Idle') {
          logSuspend(props.qmpadId);
        }
        break;

      case 'Paused':
        if (logSuspendReasonResult.status === 'Idle') {
          if (selectedReason !== 'other') {
            logSuspendReason(props.qmpadId, selectedReason);
          } else {
            logSuspendReason(props.qmpadId, note);
          }
        }
        break;

      case 'Unpausing':
        props.onCloseClick?.();
        setPauseState('Unpaused');
        break;
    }
  }, [pauseState, logSuspendResult, logSuspend, logSuspendReasonResult, logSuspendReason, selectedReason, props, note]);

  const checkCanSubmit = (): boolean => {
    if (selectedReason === 'none' || (selectedReason === 'other' && !note)) {
      return false;
    }
    return true;
  };

  const canSubmit = checkCanSubmit();

  return (
    <Overlay open={props.open}>
        <DialogBody>
          <TabContent open={pauseState === 'ChooseReason'}>
            <Content>
              <FontAwesomeIcon icon={['far', 'clock']} size='3x'/>
              <h2>Pozastavená směna</h2>
              <h3>Vyberte motiv</h3>
                <select onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setSelectedReason(e.target.value)}>
                  <option value='none'>Žádný</option>
                  <option value='nobody'>Nikde nikdo…</option>
                  <option value='doctor'>Odchod k lékaři</option>
                  <option value='lunch'>Přestávka na oběd</option>
                  <option value='wc'>WC</option>
                  <option value='other'>Jiný</option>
                </select>

              {selectedReason === 'other' && (
                  <input type={'text'} placeholder={'Prosím, zde zadejte důvod'} value={note} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNote(e.target.value)}></input>
              )}
            <Button disabled={!canSubmit} icon={'check'} click={() => setPauseState('Paused')}>POTVRDIT</Button>
            </Content>
          </TabContent>

            <TabContent open={pauseState === 'Paused'}>
              <Content>
                <FontAwesomeIcon icon={['far', 'clock']} size='3x'/>
                <h2>Pozastavená směna</h2>
                <Button disabled={!canSubmit || pauseState !== 'Paused'} icon={'check'} click={() => setPauseState('Unpausing')}>POKRAČOVAT V PRÁCI</Button>
              </Content>
            </TabContent>
        </DialogBody>
    </Overlay>
  );
};

export default PauseDeskDialog;