import React, {useEffect} from 'react';
import {UserDto} from '../../interfaces/dto';
import useUsersService, {UsersWrapper} from '../../services/use-users-service';

interface IProps {
  onChange?: (user: UserDto) => void;
  size? : number;
}

const UserSelector: React.FC<IProps> = props => {
  const {usersResult, loadUsers} = useUsersService();

  useEffect(() => {
    if (usersResult.status === 'Idle') {
      loadUsers();
    }
  }, [usersResult, loadUsers]);

  const selectChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (props.onChange) {
      if (usersResult.status === 'Ready') {
        const selected = usersResult.payload.users.find((i: UsersWrapper) => i.user.id === event.target.value)?.user;
        if (selected) {
          props.onChange(selected);
        }
      }
    }
  };

  return (
    <select onChange={selectChanged} size={props.size}>
        {
          usersResult.status === 'Ready' && usersResult.payload.users.map(i => (
            <option key={i.user.id} value={i.user.id}>{i.user.username}</option>
          ))
        }
    </select>
  );
};

export default UserSelector;
