import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import theme from '../theme';

const Square = styled.div`
  width: 1em;
  height: 1em;
  position: relative;
  box-sizing: border-box;
  border: 0.1em solid ${theme.color.darkCyan};
  border-radius: 0.1em;
  background: ${theme.color.white};
  transition: ${theme.transition.fast};

  .foreground, .foreground svg{
    width: 90%;
    height: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &.hoverable:hover {
    border-color: ${theme.color.cyan};
    &.checked {
      background: ${theme.color.cyan};
    }
  }
  &.checked {
    background: ${theme.color.darkCyan};
    color: ${theme.color.white};
  }
`;

interface IProps {
  checked: boolean;
  readonly?: boolean;
  disableHover?: boolean;
  onChange?: (checked: boolean) => void;
}

const CheckBox: React.FC<IProps> = props => {
  const [isChecked, setIsChecked] = useState<boolean>(props.checked);

  useEffect(() => {
    setIsChecked(props.checked);
  }, [props.checked]);

  const toggle = () => {
    const value = !isChecked;
    setIsChecked(value);
    if (props.onChange) props.onChange(value);
  };

  const getClassName = () => {
    let className = '';
    if (isChecked) {
      className = 'checked';
    }
    if (!props.disableHover) {
      className += className ? ' hoverable' : 'hoverable';
    }
    return className;
  };

  return (
    <Square className={getClassName()} onClick={() => props.readonly ? '' : toggle()}>
      { isChecked && (
        <FontAwesomeIcon className={'foreground'} icon={['fas', 'check']} size='1x' />
      )}
    </Square>
  );
};

export default CheckBox;