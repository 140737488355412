import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import allActions from '../actions';
import Dialog from '../components/dialog/dialog';
import {configValidated} from '../ipc-communicator';
import useConfigService from '../services/use-config-service';

const ConfigLoader: React.FC = () => {
  const {configResult, loadConfig} = useConfigService();
  const dispatch = useDispatch();
  const [state, setState] = useState<'Loading' | 'OK'>('Loading');
  const [error, setError] = useState<Error | undefined>(undefined);

  useEffect(() => {
    if (state === 'Loading') {
      if (configResult.status === 'Idle') {
        loadConfig();
      } else if (configResult.status === 'Ready') {
        const config = configResult.payload.config;
        console.log(config); // tslint:disable-line:no-console
        dispatch(allActions.config.setConfig(config));
        setError(configResult.payload.error);
        configValidated(config);
        setState('OK');
      }
    }
  }, [configResult, state]); // eslint-disable-line

  return (
    <>
      {error && (
        <Dialog open={true} onCloseClick={() => setError(undefined)}>
          <h2 style={{marginBottom: 0}}>Problém při načítání konfiguračního souboru</h2>
          <p style={{marginTop: 5}}>{error.message}</p>
        </Dialog>
      )}
    </>
  );
};

export default ConfigLoader;
