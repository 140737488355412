import React, {useState} from 'react';
import styled from 'styled-components';
import {PrinterDto, ServiceDto} from '../../interfaces/dto';
import {CreateTicketRequest} from '../../services/use-ticket-service';
import ButtonBase from '../button/button';
import Overlay from '../overlay';
import Row from '../row/row';
import PrinterSelector from '../select/printer-selector';
import ServiceSelector from '../select/service-selector';
import TabContent from '../tab';
import {DialogBody, DialogFooter, DialogHeader, DialogWindow} from './dialog';

export interface IResult extends CreateTicketRequest {
  service: ServiceDto;
}

interface IProps {
  onCloseClick?(event: Event): void;
  onSubmitClick?(event: Event, result: IResult): void;
  open: boolean;
}

const Content = styled.div`
  min-width: 500px;
  *{
    box-sizing:border-box;
  }
  button{
    box-sizing:border-box;
    padding: 5px;
  }
  h3{
    margin: 0;
    font-size: .8em;
    font-weight: 600;
  }
  select, textarea{
    box-sizing: border-box;
    width: 100%;
  }
  option{
    padding: 5px;
  }
`;

const Button = styled(ButtonBase)`
  display:flex;
  align-items: center;
`;

const CreateTicketDialog: React.FC<IProps> = (props: React.PropsWithChildren<IProps>) => {
  const [selectedService, setSelectedService] = useState<ServiceDto | undefined>(undefined);
  const [selectedPrinter, setSelectedPrinter] = useState<PrinterDto | undefined>(undefined);
  const [openedTab, setOpenedTab] = useState<'Service' | 'Printer'>('Service');

  const close = (event: Event) => {
    props.onCloseClick?.(event);
  };

  const sumbit = (event: Event, call: boolean) => {
    if (selectedService) {
      props.onSubmitClick?.(event, {service: selectedService, call, printer: openedTab === 'Printer' ? selectedPrinter : undefined});
    }
  };

  return (
    <>
      <Overlay open={props.open}>
        <DialogWindow>
          <DialogHeader>Nový lístek</DialogHeader>

          <TabContent open={openedTab === 'Service'}>
            <DialogBody>
              <Content>
                <h3>Vyberte službu</h3>
                <ServiceSelector size={8} onChange={(service: ServiceDto) => setSelectedService(service)} />
              </Content>
            </DialogBody>
            <DialogFooter style={{padding: '0 15px 15px 15px'}}>
              <Button icon='times' click={close}>Zrušit</Button>
              <Row>
                <Button disabled={!selectedService} click={(e: Event) => sumbit(e, false)}>Vytvořit lístek</Button>
                <Button disabled={!selectedService} icon={'plus'} click={(e: Event) => sumbit(e, true)}>Vytvořit a zavolat lístek</Button>
              </Row>
            </DialogFooter>
          </TabContent>

          <TabContent open={openedTab === 'Printer'}>
            <DialogBody>
              <Content>
                <h3>Vyberte výdejník lístků / uživatelskou tiskárnu</h3>
                <PrinterSelector size={8} onChange={(printer: PrinterDto) => setSelectedPrinter(printer)} />
              </Content>
            </DialogBody>
            <DialogFooter style={{padding: '0 15px 15px 15px'}}>
              <Button icon={'angle-left'} click={() => setOpenedTab('Service')}>Zpět</Button>
              <Button icon={'print'} disabled={!selectedPrinter} click={sumbit}>Vytvořit a vytisknout lístek</Button>
            </DialogFooter>
          </TabContent>

        </DialogWindow>
      </Overlay>
    </>
  );
};

export default CreateTicketDialog;