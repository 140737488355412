import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';

const Animator = styled.div`
  svg {
      animation:spin 1.2s cubic-bezier(0.2, 0.575, 0.565, 1) infinite;
  }
  @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
`;

const LoadingIndicator: React.FC = () => {

  return (
    <Animator>
      <FontAwesomeIcon icon={['fas', 'sync-alt']} size='5x' />
    </Animator>
  );
};

export default LoadingIndicator;