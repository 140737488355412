import {useState} from 'react';
import {Response} from '../interfaces/api';
import {ConnectionDto, PrinterDto, TicketDto} from '../interfaces/dto';
import store from '../store';
import {cancelableFetch, IFetchData} from './service-processer';

export interface CreateTicketRequest {
  call: boolean;
  printer?: PrinterDto;
}

export interface TicketWrapper {
  ticket: TicketDto;
  request: CreateTicketRequest;
}

const useTicketService = () => {
  const [ticketResult, setAddTicketResult] = useState<Response<TicketWrapper>>({
    status: 'Idle'
  });

  const addTicket = (serviceId: string, request: CreateTicketRequest) => {
    const bloom: ConnectionDto = store.getState().config.current.bloom;
    const fetchData: IFetchData = {
      uri: `http://${bloom.host}:${bloom.port}/api/1/json/ticket`,
      method: 'POST',
      headers: {'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8'},
      body: `sid=${serviceId}`,
      parse: 'json'
    };

    return cancelableFetch(fetchData, setAddTicketResult, (data: any) => fixTicketId(data, request));
  };

  return {ticketResult, addTicket};
};

const fixTicketId = (data: any, request: CreateTicketRequest) => {
  data.ticket.id = data.ticket.ticket_id; // ticket id is named ticket_id in this json (normally it is only id)
  delete data.ticket.ticket_id;
  return {ticket: data.ticket, request};
};

export default useTicketService;
