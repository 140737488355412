import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
} from 'react-router-dom';
import './application.css';
import DeskUpdater from './components/desk-updater';
import './fontawesome';
import {DeskDto, TicketDto, UserDto} from './interfaces/dto';
import Dashboard from './pages/dashboard';
import DeskSelectPage from './pages/desk-select-page';
import LoginPage from './pages/login-page';
import QBusConnection, {QBusConsumer} from './qbus-connection';

const electron = window.require('electron');
document.title = 'QPadPro v.' + electron.remote.app.getVersion();

const Application = () => {
  const [user, setUser] = useState<UserDto>();
  const [desk, setDesk] = useState<DeskDto>();
  const [qmpadId, setQmpadId] = useState<string>('');
  const [startingTicket, setStartingTicket] = useState<TicketDto>();
  const config = useSelector(state => state.config);

  const handleLogout = () => {
    setUser(undefined);
    setDesk(undefined);
    setQmpadId('');
  };

  function PrivateRoute({children, ...rest}) {
    return (
        <Route
            {...rest}
            render={() =>
                user ? (
                  (desk && qmpadId) ? (
                      children
                    ) : (
                      <Redirect
                        to={{
                          pathname: '/deskselect',
                        }}
                      />
                  )
                ) : (
                        <Redirect
                            to={{
                                pathname: '/login',
                            }}
                        />
                    )
            }
        />
    );
  }

  return (
    <>
      {config.initialized && (
        <Router>
          <PrivateRoute path='/'>
            <Redirect to='/dashboard'/>
          </PrivateRoute>
          <PrivateRoute path='/dashboard'>
            {(user && desk) && (
              <QBusConnection userId={user.id} qmpadId={qmpadId} deskId={desk.id} startingTicket={startingTicket} onLogout={handleLogout}>
                <QBusConsumer>
                  {qbus => qbus && (
                      <>
                        <DeskUpdater />
                        <Dashboard desk={desk} qbus={qbus} user={user} onLogout={handleLogout} />
                      </>
                    )
                  }
                </QBusConsumer>
              </QBusConnection>
            )}
          </PrivateRoute>
          <Route exact path='/login'>
            <LoginPage onSuccess={(u: UserDto, d?: DeskDto) => {setDesk(d); setUser(u); }}/>
          </Route>
          <Route exact path='/deskselect'>
            <DeskSelectPage desk={desk} onSubmit={(d: DeskDto, qId: string) => {setDesk(d); setQmpadId(qId); setStartingTicket(user?.ticket); }}/>
          </Route>
        </Router>
      )}
    </>
  );
};

export default Application;