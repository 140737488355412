import {TicketDto} from '../interfaces/dto';

interface SetSource {
  type: 'SET_SOURCE';
  payload: string;
}

interface SetTickets {
  type: 'SET_TICKETS';
  payload: TicketDto[];
}

interface TakeTicket {
  type: 'TAKE_TICKET';
  payload: TicketDto;
}

interface CloseTicket {
  type: 'CLOSE_TICKET';
}

type TicketsAction = SetSource | SetTickets | TakeTicket | CloseTicket;

export interface TicketsState {
  list: TicketDto[];
  taken: TicketDto | null;
}

const initialState = {
  list: [],
  taken: null,
  source: null,
};

const tickets = (state: TicketsState = initialState, action: TicketsAction) => {
  switch (action.type) {
    case 'SET_TICKETS': return {...state, list: action.payload};
    case 'TAKE_TICKET': return {...state, taken: action.payload};
    case 'SET_SOURCE': return {...state, source: action.payload};
    case 'CLOSE_TICKET': return {...state, taken: null};
    default: return state;
  }
};

export default tickets;
