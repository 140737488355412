import {ConfigDto} from '../interfaces/dto';

const defaultConfig: ConfigDto = {
  frontend: 'http://qpadpro.lowenware.com/',
  bloom: {host: '89.239.8.90', port: '8080'},
  devices: [{name: 'ExampleDevice', address: '192.168.1.108'}],
  notifications: {popup: false, sound: false, system: true},
  toggles: [
    {
      device: 'ExampleDevice',
      show: {
        on: 'Open door',
        off: 'Close door'
      },
      action: {
        on: '',
        off: ''
      },
      payload: {
        on: '\\02DJ\\221*1234 PGON 1\\22\\03',
        off: '\\02DJ\\221*1234 PGOFF 1\\22\\03'
      }
    }
  ]
};

export default defaultConfig;