import {useState} from 'react';
import {Response} from '../interfaces/api';
import {ConnectionDto, ServiceDto} from '../interfaces/dto';
import store from '../store';
import {cancelableFetch, IFetchData} from './service-processer';

export interface ServiceWrapper {
  service: ServiceDto;
}

export interface Services {
  success: boolean;
  services: ServiceWrapper[];
}

const useServicesService = () => {
  const [servicesResult, setLoadServicesResult] = useState<Response<Services>>({
    status: 'Idle'
  });

  const loadServices = () => {
    const bloom: ConnectionDto = store.getState().config.current.bloom;
    const fetchData: IFetchData = {
      uri: `http://${bloom.host}:${bloom.port}/api/1/json/services`,
      method: 'GET',
      parse: 'json'
    };

    return cancelableFetch(fetchData, setLoadServicesResult);
  };

  return {servicesResult, loadServices};
};

export default useServicesService;
