import {isFunction} from 'util';
import {ConfigDto} from './interfaces/dto';
const electron = window.require('electron');

const ipc = electron.ipcRenderer;
const invokeCallback = (callback: any, result: Result<any>) => {
 if (callback !== undefined && isFunction(callback)) {
   callback(result);
 }
};

export interface Success<T> {
  success: true;
  payload: T;
}

interface Exception {
  success: false;
  error: Error;
}

export type Result<T> = Success<T> | Exception;

interface IToggleRequest {
  device: string;
  value: boolean;
  payload: string;
}
interface INotification {
  title: string;
  body: string;
}

export interface IConfigInfo {
  path: string | null;
  error?: string;
}

export interface IReadConfigResult {
  defaultConfig: IConfigInfo;
  userConfig: IConfigInfo;
  parsedConfig?: ConfigDto;
}

export function sendNotification(title: string, body: string) {
  const notification: INotification = {title, body};
  ipc.send('notification', notification);
}

export function readConfig(callback: (result: Success<IReadConfigResult>) => void) {
  ipc.invoke('config-read').then((result: Success<IReadConfigResult>) => invokeCallback(callback, result));
}

export function saveConfig(data: string, callback: (result: Result<string>) => void) {
  ipc.invoke('config-save', data).then((result: Result<null>) => invokeCallback(callback, result));
}

export function toggle(device: string, value: boolean, payload: string) {
  const request: IToggleRequest = {device, value, payload};
  ipc.send('toggle', request);
}

export function configValidated(config: ConfigDto) {
  ipc.send('config-validated', config);
}