import React from 'react';
import './column.css';

const Column: React.FC = props => {
  return (
    <div className='flex-column'>{props.children}</div>
  );
};

export default Column;
