import {ServiceDto} from '../interfaces/dto';

const setDeskId = (deskId: string) => {
  return {
    type: 'SET_DESK_ID',
    payload: deskId,
  };
};

const setServices = (list: ServiceDto[]) => {
  return {
    type: 'SET_SERVICES',
    payload: list,
  };
};

const selectService = (service: ServiceDto) => {
  return {
    type: 'SELECT_SERVICE',
    payload: service.id,
  };
};

const selectServices = (services: string[]) => {
  return {
    type: 'SELECT_SERVICES',
    payload: services,
  };
};

const unselectService = (service: ServiceDto) => {
  return {
    type: 'UNSELECT_SERVICE',
    payload: service.id,
  };
};

export default {
  setDeskId, setServices, selectService, selectServices, unselectService
};
