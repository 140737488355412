import {useState} from 'react';
import {Response} from '../interfaces/api';
import {ConnectionDto} from '../interfaces/dto';
import store from '../store';
import {cancelableFetch, IFetchData} from './service-processer';

const useLogSuspendService = () => {
  const [logSuspendResult, setLogSuspendResult] = useState<Response<any>>({
    status: 'Idle'
  });
  const [logSuspendReasonResult, setLogSuspendReasonResult] = useState<Response<any>>({
    status: 'Idle'
  });

  const logSuspend = (qmpadId: string) => {
    const bloom: ConnectionDto = store.getState().config.current.bloom;
    const fetchData: IFetchData = {
      uri: `http://${bloom.host}:${bloom.port}/qm/qmpad/logsuspend/${qmpadId}`,
      method: 'POST',
      parse: 'json'
    };
    return cancelableFetch(fetchData, setLogSuspendResult);
  };

  const logSuspendReason = (qmpadId: string, reason: string) => {
    const bloom: ConnectionDto = store.getState().config.current.bloom;
    const fetchData: IFetchData = {
      uri: `http://${bloom.host}:${bloom.port}/qm/qmpad/logsuspendreason/${qmpadId}`,
      method: 'POST',
      body: new URLSearchParams({reason}),
      parse: 'json'
    };
    return cancelableFetch(fetchData, setLogSuspendReasonResult);
  };

  return {logSuspendResult, logSuspend, logSuspendReasonResult, logSuspendReason};
};

export default useLogSuspendService;