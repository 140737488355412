import React from 'react';
import styled from 'styled-components';
import theme from '../theme';

interface IProps {
  open: boolean;
}

export const TabSelection = styled.div`
  display: flex;
  justify-content: stretch;

  button{
    flex: 1 1 0;
    border: 1px solid gray;
    &.active{
      background: ${theme.color.darkCyan};
      border-color: ${theme.color.darkCyan};
      color: ${theme.color.white};
    }
  }
`;

const TabContent: React.FC<IProps> = props => {

  return (
    <div style={{display: (props.open ? 'block' : 'none')}}>
      {props.children}
    </div>
  );
};

export default TabContent;