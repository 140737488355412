import React from 'react';
import styled from 'styled-components';
import theme from '../../theme';
import Overlay, {OverlayContent} from '../overlay';

interface IProps {
  onCloseClick?(event: Event): void;
  open: boolean;
  closeButtonText?: string;
}

export const DialogWindow = styled(OverlayContent)`
`;

export const DialogHeader = styled.div`
  padding: 5px 15px;
  background: ${theme.color.darkCyan};
  color: ${theme.color.white};
`;

export const DialogBody = styled.div`
  padding: 15px;
  `;

export const DialogFooter = styled.div`
  display:flex;
  justify-content: space-between;

  button {
  padding: 5px;
  min-width: 50px;
  }
`;

const Content = styled(OverlayContent)`
  padding: 20px 40px;
  text-align: center;
`;

const CloseButton = styled.button`
  box-sizing: border-box;
  padding: 10px 20px;
  align-self: center;
  margin-top: auto;
`;

const Dialog: React.FC<IProps> = (props: React.PropsWithChildren<IProps>) => {
  const close = (event: Event) => {
    props.onCloseClick?.(event);
  };

  return (
    <Overlay open={props.open}>
      <Content>
        {props.children}
        <CloseButton onClick={close}>
          {props.closeButtonText ? props.closeButtonText : 'OK'}
        </CloseButton>
      </Content>
    </Overlay>
  );
};

export default Dialog;