import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Column from '../components/column/column';
import ControlToggles from '../components/dashboard/control-toggles';
import InfoBar from '../components/info-bar/info-bar';
import LoadingScreen from '../components/loading/loading-screen';
import MenuBar from '../components/menu-bar/menu-bar';
import QueueBar from '../components/queue-bar';
import Toggle from '../components/toggle/toggle';
import ToolBar from '../components/tool-bar/tool-bar';
import eventManager, {EventType} from '../event-manager';
import {DeskDto, UserDto} from '../interfaces/dto';
import {QBus} from '../qbus';
import useLoginService from '../services/use-login-service';

interface IProps {
  qbus: QBus;
  desk: DeskDto;
  user: UserDto;
  onLogout: () => void;
}

const Controls = styled.div`
  padding: 24px 0 10vh;
  display: flex;
  flex-direction: row;
`;

const Dashboard: React.FC<IProps> = props => {
  const {logoutResult, logout} = useLoginService();
  const [logoutState, setLogoutState] = useState<'None' | 'Logout' | 'OK' >('None');
  const onLogout = props.onLogout;

  useEffect(() => {
    if (logoutState === 'Logout') {
      if (logoutResult.status === 'Idle') {
        logout();
      } else if (logoutResult.status === 'Ready') {
        onLogout();
        setLogoutState('OK');
      }
    }
  }, [logoutState, logoutResult, logout, onLogout]);

  return (
    <>
      {
        logoutState === 'None' ? (
            <Column>
            <MenuBar desk={props.desk} user={props.user} qmpadId={props.qbus.id} onLogout={() => setLogoutState('Logout')}></MenuBar>
            <ToolBar qbus={props.qbus}></ToolBar>
            <InfoBar></InfoBar>
            <Controls>
              <Toggle labelOn='Vstup povolen' labelOff='Nevstupovat' value={true}
              onToggle={value => {
                props.qbus.sendSetDeskLEDServiceBusy(props.desk.id, !value);
                eventManager.invokeEvent(value ? EventType.enterAllowed : EventType.enterProhibited);
              }}/>
              <ControlToggles qbus={props.qbus}/>
            </Controls>
            <QueueBar qbus={props.qbus}></QueueBar>
          </Column>

        ) : (
          <LoadingScreen/>
        )
      }
    </>
  );
};

export default Dashboard;
