import React, {useState} from 'react';
import styled from 'styled-components';
import {DeskDto, ServiceDto, UserDto} from '../../interfaces/dto';
import Button from '../button/button';
import Overlay from '../overlay';
import DeskSelector from '../select/desk-selector';
import ServiceSelector from '../select/service-selector';
import UserSelector from '../select/user-selector';
import TabContent, {TabSelection} from '../tab';
import {DialogBody, DialogFooter, DialogHeader, DialogWindow} from './dialog';

export interface IResult {
  destinationType: 'Service' | 'User' | 'Desk';
  destinationId: string;
  priority: boolean;
  note: string;
}

interface IProps {
  onCloseClick?(event: Event): void;
  onSubmitClick?(event: Event, result: IResult): void;
  open: boolean;
}

const Content = styled.div`
  min-width: 300px;
  *{
    box-sizing:border-box;
  }
  button{
    box-sizing:border-box;
    padding: 5px;
  }
  h3{
    margin: 10px 0 0 0;
    font-size: .8em;
    font-weight: 600;
  }
  select, textarea{
    box-sizing: border-box;
    width: 100%;
  }
  option{
    padding: 5px;
  }
`;

const PriorityDiv = styled.div`
  padding: 10px 0 10px 0;
  display:flex;
  align-items: center;

  h3{
    margin: 0;
  }
`;

const Checkbox = styled.input.attrs({type: 'checkbox'})`
  width: 1.4em;
  height: 1.4em;
`;

const TicketForwardDialog: React.FC<IProps> = (props: React.PropsWithChildren<IProps>) => {
  const [openedTab, setOpenedTab] = useState<'Service' | 'User' | 'Desk'>('Service');
  const [selectedService, setSelectedService] = useState<ServiceDto | null>(null);
  const [selectedUser, setSelectedUser] = useState<UserDto | null>(null);
  const [selectedDesk, setSelectedDesk] = useState<DeskDto | null>(null);
  const [priority, setPriority] = useState<boolean>(false);
  const [note, setNote] = useState<string>('');

  const close = (event: Event) => {
    props.onCloseClick?.(event);
  };

  const getDestinationId = (): string | undefined => {
    switch (openedTab) {
      case 'Service':
        return selectedService?.id;
      case 'User':
        return selectedUser?.id;
      case 'Desk':
        return selectedDesk?.id;
      default:
        return undefined;
    }
  };
  const destinationId = getDestinationId();

  const sumbit = (event: Event) => {
    if (destinationId) {
      props.onSubmitClick?.(event, {
          destinationType: openedTab,
          destinationId,
          priority: priority || false,
          note: note || '',
        }
      );
    }
  };

  return (
    <Overlay open={props.open}>
      <DialogWindow>
        <DialogHeader>Převést tento lístek do:</DialogHeader>
        <DialogBody>
          <Content>
            <TabSelection>
              <button className={openedTab === 'Service' ? 'active' : ''} onClick={() => setOpenedTab('Service')}>Služba</button>
              <button className={openedTab === 'User' ? 'active' : ''} onClick={() => setOpenedTab('User')}>Uživatel</button>
              <button className={openedTab === 'Desk' ? 'active' : ''} onClick={() => setOpenedTab('Desk')}>Přepážka</button>
            </TabSelection>

            <TabContent open={openedTab === 'Service'}>
              <h3>Filtr podle skupiny služeb</h3>
              <select name='example'>
                <option value='item1'>Všechny skupiny</option>
                <option value='item2'>Not implemented</option>
              </select>
              <h3>Vybrat cílovou službu</h3>
              <ServiceSelector size={4} onChange={(service: ServiceDto) => setSelectedService(service)} />
            </TabContent>

            <TabContent open={openedTab === 'User'}>
              <h3>Vybrat cílového uživatele</h3>
              <UserSelector size={4} onChange={(user: UserDto) => setSelectedUser(user)} />
            </TabContent>

            <TabContent open={openedTab === 'Desk'}>
              <h3>Vybrat cílovou přepážku</h3>
              <DeskSelector size={4} onChange={(desk: DeskDto) => setSelectedDesk(desk)}></DeskSelector>
            </TabContent>

            <h3>Přidat poznámku k tomuto lístku</h3>
            <textarea rows={2} placeholder={'Zde napište poznámku'} value={note} onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setNote(e.target.value)}></textarea>
            <PriorityDiv>
              <Checkbox checked={priority} onChange={(e: React.ChangeEvent<any>) => setPriority(e.target.checked)} />
              <h3>Přidat lístku prioritu</h3>
            </PriorityDiv>
          </Content>
          <DialogFooter>
            <Button click={close}>ZRUŠIT</Button>
            <Button disabled={!destinationId} click={sumbit}>PŘEVÉST</Button>
          </DialogFooter>
        </DialogBody>
      </DialogWindow>
    </Overlay>
  );
};

export default TicketForwardDialog;