import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import device from '../../device';
import {DeskDto, UserDto} from '../../interfaces/dto';
import theme from '../../theme';
import Dialog from '../dialog/dialog';
import PauseDeskDialog from '../dialog/pause-desk-dialog';
import Separator from '../separator/separator';

interface IProps {
  desk: DeskDto;
  user: UserDto;
  qmpadId: string;
  onLogout: () => void;
}

const MenuBarElement = styled.div`
  select{
    line-height: 1.1em;
    font-size: 1.1em;
    margin-right: 24px;
  }

  .menu-bar.rolled{
    display: block;
  }
  .rolled button {
    display: block;
    width: 100%;
    position: relative;
    z-index: 100;
  }
  .rolled select{
    display:none;
  }
  .rolled .menu-button{
    width: auto;
  }
  .user-info {
    color:${theme.color.white};
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 24px;
    font-size: .9em;
    text-align: left;

    span {
      margin-left: .45em;
    }
  }

  @media ${device.mobile} {
    button{
      display:none;
    }
    .menu-button{
      display:block;
    }
  }

  @media ${device.tablet} {
    button{
      display:block;
    }
    .menu-button{
      display:none;
    }
    .rolled{
      display: inline-block;
    }
  }
`;

const MenuList = styled.div`
  display: flex;
  flex-direction: row;
  background: ${theme.color.darkCyan};
  height: 10vw;
  min-height: 48px;
  max-height: 128px;
  align-items: center;
  padding: 0;
`;

const ItemButton = styled.button`
  background: ${theme.color.darkCyan};
  color:${theme.color.white};
  height: 100%;
  padding: 0 24px;
  font-size: 1.1em;
  text-align: left;

  span {
    padding-left: 16px;
  }
`;

const MenuBar: React.FC<IProps> = (props: IProps) => {
  const [isRolled, Roll] = useState<boolean>(false);
  const [dialog, setDialog] = useState<null | 'PauseDeskDialog' | 'SendMessageDialog' | 'TakenTicket'>(null);
  const takenTicket = useSelector(state => state.tickets.taken);

  const logoutClick = () => {
    takenTicket ? setDialog('TakenTicket') : props.onLogout();
    Roll(false);
  };

  const pauseDeskClick = () => {
    takenTicket ? setDialog('TakenTicket') : setDialog('PauseDeskDialog');
    Roll(false);
  };

  return (
    <>
    <MenuBarElement>
        <MenuList className={isRolled ? 'menu-bar rolled' : 'menu-bar'}>
          <ItemButton className='menu-button' onClick={() => Roll(!isRolled)}>
            <FontAwesomeIcon icon={['fas', 'bars']} size='1x' />
          </ItemButton>
          <ItemButton onClick={logoutClick}>
            <FontAwesomeIcon icon={['fas', 'sign-out-alt']} size='1x' />
            <span>Odhlásit</span>
          </ItemButton>
          <Separator />
          <div className='user-info'>
            <FontAwesomeIcon icon={['fas', 'user']} size='1x' />
            <span>{props.user.username}</span>
            <span>|</span>
            <span>{props.desk.description}</span>
          </div>
        </MenuList>
      </MenuBarElement>
      {dialog === 'PauseDeskDialog' && (
        <PauseDeskDialog open={true} qmpadId={props.qmpadId} onCloseClick={() => setDialog(null)}/>
      )}
      {dialog === 'TakenTicket' && (
        <Dialog open={true} onCloseClick={() => setDialog(null)}>
          <h2>Nejprve obslužte lístek</h2>
        </Dialog>
      )}
    </>
  );
};

export default MenuBar;

// TODO: Implement "Poslat zprávu" and "Pozastavit relace"
/*
        <ItemButton>
          <FontAwesomeIcon icon={['fas', 'comment']} size='1x' />
          <span>Poslat zprávu</span>
        </ItemButton>
        <ItemButton onClick={pauseDeskClick}>
          <FontAwesomeIcon icon={['fas', 'hourglass-start']} size='1x' />
          <span>Pozastavit relace</span>
        </ItemButton>
*/