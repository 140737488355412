import {useState} from 'react';
import {isString} from 'util';
import defaultConfig from '../config/default-config';
import {Response} from '../interfaces/api';
import {ConfigDto, NotificationsDto} from '../interfaces/dto';
import {IConfigInfo, IReadConfigResult, readConfig, saveConfig, Success} from '../ipc-communicator';

export interface ConfigResult {
  error?: Error;
  config: ConfigDto;
}

const useConfigService = () => {
  const [configResult, setResult] = useState<Response<ConfigResult>>({
    status: 'Idle'
  });

  const loadConfig = () => {
    setResult({status: 'Loading'});
    readConfig((result: Success<IReadConfigResult>) => {
      const error: Error | undefined = getError(result.payload.userConfig, true) || getError(result.payload.defaultConfig, false);
      if (result.payload.parsedConfig) {
        try {
          const config = validateConfig(result.payload.parsedConfig);
          setResult({status: 'Ready', payload: {config, error}});
        } catch (exception) {
          setResult({status: 'Ready', payload: {config: defaultConfig, error: new Error(`${error ? error.toString() + '\n' : ''}Problém při validaci: ${exception}`)}});
        }
      } else {
        setResult({status: 'Ready', payload: {config: defaultConfig, error}});
        saveConfig(JSON.stringify(defaultConfig, null, '\t'), () => {});
      }
    });
  };

  const getError = (cfg: IConfigInfo, userConfig: boolean) => {
    if (cfg && cfg.path && cfg.error) {
      if (cfg.error === 'File not exists') {
        if (userConfig) {
          return new Error(`Nelze načíst konfigurační soubor! Prosím, zkontrolujte, že soubor "${cfg.path}" existuje, a máte oprávnění k jeho čtení.`);
        }
      } else if (cfg.error.toString().startsWith('SyntaxError') && cfg.error.toString().includes('JSON')) {
        return new Error(`Nelze načíst konfigurační soubor! Prosím, zkontrolujte, že soubor "${cfg.path}" je validní JSON. ${cfg.error}`);
      } else {
        return new Error(cfg.error);
      }
    }
    return undefined;
  };

  const validateConfig = (c: ConfigDto): ConfigDto => {
    const isTrue = (value: any): boolean => {
      // accepts true, false, 'true', 'false' or undefined
      return value === true || (isString(value) && value.toLowerCase() === 'true');
    };

    const frontend = (c.frontend) ? c.frontend : defaultConfig.frontend;
    const bloom = (c.bloom && c.bloom.host && c.bloom.port) ? c.bloom : defaultConfig.bloom;
    const devices = (c.devices) ? c.devices.filter(d => d.name && d.address) : defaultConfig.devices;
    const n = c.notifications;
    const notifications: NotificationsDto = (isTrue(n.popup) || isTrue(n.sound) || isTrue(n.system)) ? {
      popup: isTrue(n.popup),
      sound: isTrue(n.sound),
      system: isTrue(n.system)
    } : defaultConfig.notifications;
    const toggles = c.toggles || defaultConfig.toggles;
    // const printers = c.printers || defaultConfig.printers;
    return {frontend, bloom, devices, notifications, toggles};
  };

  return {configResult, loadConfig};
};

export default useConfigService;
