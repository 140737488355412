import {useState} from 'react';
import {Response} from '../interfaces/api';
import {ConnectionDto} from '../interfaces/dto';
import store from '../store';
import {cancelableFetch, IFetchData} from './service-processer';

export interface LoginResult {
  success: boolean;
  userId?: string;
  deskId?: string;
}

interface HttpResponse {
  status: number;
  type: string;
}

const useLoginService = () => {
  const [loginResult, setLoginResult] = useState<Response<LoginResult>>({
    status: 'Idle'
  });
  const [logoutResult, setLogoutResult] = useState<Response<string>>({
    status: 'Idle'
  });

  const login = (username: string, password: string) => {
    const bloom: ConnectionDto = store.getState().config.current.bloom;
    const fetchData: IFetchData = {
      uri: `http://${bloom.host}:${bloom.port}/loginqmpad`,
      method: 'POST',
      headers: {'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8'},
      body: `username=${username}&password=${password}`,
      credentials: 'omit',
      redirect: 'manual',
    };
    return cancelableFetch(fetchData, setLoginResult, (data: HttpResponse) => {
      return {success: (data.status === 0 && data.type === 'opaqueredirect')};
    });
  };

  const logout = () => {
    const bloom: ConnectionDto = store.getState().config.current.bloom;
    const fetchData: IFetchData = {
      uri: `http://${bloom.host}:${bloom.port}/login/logoutqmpad`,
      method: 'GET',
    };
    return cancelableFetch(fetchData, setLogoutResult);
  };

  return {loginResult, login, logoutResult, logout};
};

export default useLoginService;
