import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import allActions from '../../../actions';
import {ServiceDto} from '../../../interfaces/dto';
import theme from '../../../theme';
import CheckBox from '../../checkbox';

interface IProps {
  service: ServiceDto;
  quantity: number;
}

const Row = styled.div`
  display:flex;
  padding: .4em;
  flex-direction: row;
  width: 100%;
  cursor: pointer;

  &:nth-child(odd) {
    background: ${theme.color.lightGray};
  }
  :hover {
    color: white;
    background: ${theme.color.cyan};
  }
`;

const Column = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  box-sizing: border-box;

  min-width: 1.2em;
  min-height: 1.2em;
  line-height: 1.2em;

  &.tag {
    align-items: flex-start;
  }
  &.descripton {
    flex-grow: 1;
    padding: 0 0 0 1em;
  }
  &.quantity {
    align-items: center;
    width: 48px;
  }
  &.checkbox div {
    width: 100%;
    height: 100%;
    margin: auto;
  }
`;

const ServiceButton: React.FC<IProps> = props => {

  const dispatch = useDispatch();
  const services = useSelector(state => state.services);

  const isEnabled = (): boolean => {
    return services.selected && services.selected.includes(props.service.id);
  };

  const toggleService = () => {
    dispatch(isEnabled() ?
      allActions.services.unselectService(props.service) :
      allActions.services.selectService(props.service));
  };

  return(
    <Row onClick={() => toggleService()}>
      <Column className={'tag'}>{props.service.tag}</Column>
      <Column className={'descripton'}>{props.service.description}</Column>
      <Column className={'quantity'}>{props.quantity}</Column>
      <Column className={'checkbox'}>
        <CheckBox checked={isEnabled()} readonly disableHover/>
      </Column>
    </Row>

  );
};

export default ServiceButton;