import {library} from '@fortawesome/fontawesome-svg-core';

import {
  faAngleDown,
  faAngleLeft,
  faBars,
  faCheck,
  faComment,
  faEllipsisH,
  faExchangeAlt,
  faExclamationCircle,
  faExternalLinkAlt,
  faHourglassStart,
  faInfo,
  faLock,
  faPause,
  faPlus,
  faPrint,
  faShare,
  faSignOutAlt,
  faStickyNote,
  faSyncAlt,
  faTicketAlt,
  faTimes,
  faToggleOff,
  faToggleOn,
  faUser,
  faUsers,
  faVolumeUp
} from '@fortawesome/free-solid-svg-icons';

import {
  faClock,
  faFile,
} from '@fortawesome/free-regular-svg-icons';

library.add(
  faAngleLeft,
  faShare,
  faBars,
  faCheck,
  faClock,
  faEllipsisH,
  faExternalLinkAlt,
  faInfo,
  faLock,
  faFile,
  faPause,
  faPlus,
  faPrint,
  faSyncAlt,
  faTicketAlt,
  faTimes,
  faToggleOn,
  faToggleOff,
  faUser,
  faUsers,
  faVolumeUp,
  faAngleDown,
  faStickyNote,
  faExclamationCircle,
  faExchangeAlt,
  faComment,
  faHourglassStart,
  faSignOutAlt
);
