import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import device from '../../device';
import {QBus} from '../../qbus';
import useTicketService from '../../services/use-ticket-service';
import Button from '../button/button';
import CreateTicketDialog, {IResult as CreateTicketDialogResult} from '../dialog/create-ticket-dialog';
import TicketForwardDialog, {IResult as TicketForwardDialogIResult} from '../dialog/ticket-forward-dialog';

interface IProps {
  qbus: QBus;
}

const ToolBarElement = styled.div`
  display: flex;
  flex: 0 1 auto;
  justify-content: space-between;
  flex-direction: column;

  div{
    display:flex;
    justify-content: center;
    padding: 12px;
  }

  div:first-child{
    padding-bottom: 0;
  }

  button {
    height: 8vh;
    width: 8vh;
    min-height: 64px;
    min-width: 64px;
    border: none;
    margin: 1px;
    align-self: center;
  }

  @media ${device.mobile}{
    button{
      flex-grow: 1;
    }
  }

  @media ${device.tablet} {
    flex-direction: row;

    div{
      padding: 24px;
    }

    div:first-child{
      padding-bottom: 24px;
    }

    button{
      flex-grow: 0;
    }
  }
`;

const MainTools = styled.div`
@media ${device.mobile} {
  button{
    flex-grow: 1;
    min-height: 96px;
    min-width: 96px;
  }
}

@media ${device.tablet} {
  button{
      min-height: 64px;
      min-width: 64px;
    }
  }
`;

const CloseTicketBtn = styled(Button)`
  background: #36a8df;
  :hover{
    background: #52d5f0;
  }
  svg{
    color: white;
  }
`;

const TransferTicketBtn = styled(Button)`
  background: #4265aa;
  :hover{
    background: #0979ee;
  }
  svg{
    color: white;
  }
`;

const PauseTicketBtn = styled(Button)`
  background: #f98b30;
  :hover{
    background: #fcc849;
  }
  svg{
    color: white;
  }
`;

const CancelTicketBtn = styled(Button)`
  background: #a51616;
  :hover{
    background: #db2323;
  }
  svg{
    color: white;
  }
`;

const ToolBar: React.FC<IProps> = props => {

  const services = useSelector(state => state.services);
  const takenTicket = useSelector(state => state.tickets.taken);
  const deskId = useSelector(state => state.services.deskId);
  const [dialog, setDialog] = useState<'None' | 'TicketForwardDialog' | 'CreateTicketDialog'>('None');
  const ticketService = useTicketService();
  const [canRecall, setCanRecall] = useState<boolean>(true);

  const callClick = () => {
    if (services.selected && services.deskId) {
      if (takenTicket) {
        recallTicket();
      }
      else {
        callTicket();
      }

      setCanRecall(false);
      setTimeout(() => setCanRecall(true), 3000);
    }
  };

  const addTicketClick = () => {
    if (!services.list.length) return;
    setDialog('CreateTicketDialog');
  };

  const closeTicketClick = () => {
    if (takenTicket) {
      props.qbus.sendCloseTicket(takenTicket);
    }
  };

  const cancelTicketClick = () => {
    if (takenTicket) {
      props.qbus.sendCancelTicket(takenTicket);
    }
  };

  const transferTicketClick = () => {
    setDialog('TicketForwardDialog');
  };

  const pauseTicketClick = () => {
    props.qbus.sendPauseTicket(takenTicket);
  };

  const createTicket = (result: CreateTicketDialogResult) => {
    ticketService.addTicket(result.service.id, result);
  };

  const transferTicket = (result: TicketForwardDialogIResult) => {
    if (takenTicket && takenTicket.answer_id) {
      props.qbus.sendTransferTicket(
        takenTicket.answer_id,
        result.destinationType,
        result.destinationId,
        result.note,
        result.priority,
      );
    }
  };

  const callTicket = () => {
    props.qbus.sendCallTicket(services.deskId, services.selected);
  };

  const recallTicket = () => {
    if (takenTicket) {
      props.qbus.sendRecallTicket(takenTicket);
    }
  };

  useEffect(() => {
    if (ticketService.ticketResult.status === 'Ready') {
      const payload = ticketService.ticketResult.payload;
      if (payload.request.call) {
        props.qbus.sendCallOneTicket(payload.ticket.id, deskId);
      }
    }
  }, [ticketService.ticketResult.status]); // eslint-disable-line

  return (
    <>
      <ToolBarElement>
        <MainTools>
          <Button icon={takenTicket ? 'volume-up' : 'plus'} title={takenTicket ? 'Znovu zavolat lístek' : 'Zavolat lístek'} click={callClick} disabled={!(!takenTicket || canRecall)} />
          <Button icon={'external-link-alt'} title='Vytvořit nový lístek' click={addTicketClick} disabled={takenTicket} />
        </MainTools>
        <div>
          <CloseTicketBtn icon='check' size='2x' title='Ukončit služby' disabled={!takenTicket} click={closeTicketClick}></CloseTicketBtn>
          <TransferTicketBtn icon='share' size='2x' title='Převést lístek' disabled={!takenTicket} click={transferTicketClick}></TransferTicketBtn>
          <PauseTicketBtn icon='pause' size='2x' title='Pozastavit lístek' disabled={!takenTicket} click={pauseTicketClick}></PauseTicketBtn>
          <CancelTicketBtn icon='times' size='2x' title='Zrušit lístek' disabled={!takenTicket} click={cancelTicketClick}></CancelTicketBtn>
        </div>
      </ToolBarElement>

      {dialog === 'TicketForwardDialog' && (
        <TicketForwardDialog open={true} onCloseClick={() => setDialog('None')} onSubmitClick={(event, result) => { transferTicket(result); setDialog('None'); }} />
      )}
      {dialog === 'CreateTicketDialog' && (
        <CreateTicketDialog open={true} onCloseClick={() => setDialog('None')} onSubmitClick={(event, result) => { createTicket(result); setDialog('None'); }} />
      )}
    </>
  );
};

export default ToolBar;
