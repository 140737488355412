import {ConfigDto} from '../interfaces/dto';

const setConfig = (config: ConfigDto) => {
  return {
    type: 'SET_CONFIG',
    payload: config,
  };
};

export default {
  setConfig
};
