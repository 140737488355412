import React from 'react';
import styled from 'styled-components';

interface IProps {
  open: boolean;
  background?: string;
}

const OverlayElement = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top:0;
  left:0;
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OverlayContent = styled.div`
  display:flex;
  flex-direction: column;
  background: white;
  box-shadow: 0 5px 30px 1px black;
  border-radius: 3px;
  overflow: hidden;
`;

const Overlay: React.FC<IProps> = (props : React.PropsWithChildren<IProps>) => {

  return (
    <>
      {props.open && (
        <OverlayElement style={{background: props.background ? props.background : 'rgba(0,0,0,0.85)'}}>
          {props.children}
        </OverlayElement>
      )}
    </>
  );
};

export default Overlay;