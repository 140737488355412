import {IconName} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import './button.css';

const StyledButton = styled.button`
  cursor: pointer;
  border: none;
  margin: 1px;
  pointer-events: none;

  .icon-label {
    margin-left: 8px;
  }
  svg {
    color: '#444';
  }
`;

type ButtonSize = undefined | '1x' | '2x' | '3x' | '4x';

interface IProps {
  size?: ButtonSize;
  icon?: string;
  click?: (event: Event) => void;
  className?: string;
  title?: string;
  disabled?: boolean;
}

const Button: React.FC<IProps> = props => {

  const handleClick = event => {
    if (props.click) props.click(event);
    event.preventDefault();
  };

  const classes = `size-${props.size || '1x'} ${props.className}`;

  return (
    <StyledButton
      className={classes}
      onClick={handleClick}
      style={{
        opacity: props.disabled ? 0.25 : 1,
        pointerEvents: props.disabled ? 'none' : 'initial',
      }}
      title={props.title ? props.title : ''}
    >
      {props.icon && (
        <FontAwesomeIcon
          icon={props.icon as IconName}
          size={props.size ? props.size : '2x'}
        />
      )}
      {props.children && (<span className={props.icon ? 'icon-label' : 'label'}>{props.children}</span>)}
    </StyledButton>
  );
};

export default Button;
