import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';
import styled from 'styled-components';
import '../extensions/dateExt';
import {ServiceDto, TicketDto} from '../interfaces/dto';
import useInterval from '../services/useInterval';
import theme from '../theme';

const Root = styled.div`
background: ${theme.color.lightCyan};
box-sizing: border-box;
margin:0 20px 0 20px;
margin-bottom: 2px;
`;

const Header = styled.button`
display: flex;
align-items: center;
box-sizing: border-box;
width: 100%;
padding: 8px 0 8px 5px;
font-size: 1.2em;
background: transparent;
:hover{
  background:transparent;
}
`;

const HeaderRow = styled.div`
display: flex;
background: ${theme.color.lightCyan};
&:hover{
  background: ${theme.color.cyan};
  }
`;

const TicketDetails = styled.div`
font-size: 1em;
box-sizing: border-box;
height: auto;
margin-left: 10px;
transition: .3s ease-in-out;
p{
  margin:0;
  padding: 5px;
}
`;

const Icon = styled.div`
margin: 0 5px 0 5px;
`;

const TakeButton = styled.button`
background: transparent;
&:hover{
  background: transparent;
  color: ${theme.color.white}
  }
`;

interface IProps {
  data: TicketDto;
  services: ServiceDto[];
  detail: TicketDto | null;
  onExpand?: (ticketId: string) => void;
  onTakeClick?: (ticketID: string) => void;
}

const Ticket: React.FC<IProps> = props => {

  const service = props.services.find(s => (s.id === props.data.service_id));
  const letter = service?.tag || '';

  const isTransfered = props.data.transferred;
  const havePriority = ((props.data.flags & (1 << 7)) !== 0);
  const time = props.data.date ? (new Date(props.data.date)).toLocaleTimeString() : '';

  const getWaitingTime = () => {
    if (!props.data.date) return '----';

    const date = new Date(props.data.date);
    const now = new Date();
    const waiting = new Date(Math.abs(now.getTime() - date.getTime()));

    return waiting.toUTCTimeString();
  };

  const [waitingTime, setWaitingTime] = useState<string>('');
  const updateWaitingTime = () => setWaitingTime(getWaitingTime());

  // counting logic
  useInterval(() => {
    if ((props.detail) && props.data.id === props.detail.id) {
      updateWaitingTime();
    }
  }, 1000);

  return (
    <Root>
      <HeaderRow>
        <Header onClick={() => {
          props.onExpand?.(props.data.id);
          updateWaitingTime();
        }
        }>
          <Icon>
            <FontAwesomeIcon icon={['fas', 'sticky-note']} size='1x' />
          </Icon>
          {havePriority && (
            <Icon>
              <FontAwesomeIcon icon={['fas', 'exclamation-circle']} size='1x' />
            </Icon>
          )}
          {isTransfered && (
            <Icon>
              <FontAwesomeIcon icon={['fas', 'exchange-alt']} size='1x' />
            </Icon>
          )}
          <span>
            <strong>
              {letter}
              {(props.data.number.toString()).padStart(3, '0')}
              {props.data.note}
            </strong>
            <span className='time'> ({time})</span>
          </span>
        </Header>
        <TakeButton onClick={() => props.onTakeClick?.(props.data.id)}>
          <FontAwesomeIcon icon={['fas', 'external-link-alt']} size='lg' />
        </TakeButton>
      </HeaderRow>

      {((props.detail) && props.data.id === props.detail.id) && (
        <TicketDetails>
          {props.detail.clientname && (
            <p><strong>Jméno: </strong>{props.detail.clientname}</p>
          )}
          {props.detail.note && (
            <p><strong>Poznámka: </strong>{props.detail.note}</p>
          )}
          {props.detail.date && (
            <p><strong>Doba čekání: </strong>
              {waitingTime}
            </p>
          )}
        </TicketDetails>
      )
      }

    </Root>
  );
};

export default Ticket;
