export const makeCancelable = (promise: any) => {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve: any, reject: any) => {
    promise.then((val: any) =>
      hasCanceled_ ? reject({isCanceled: true}) : resolve(val)
    );
    promise.catch((error: any) =>
      hasCanceled_ ? reject({isCanceled: true}) : reject(error)
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
};

/*
// https://github.com/facebook/react/issues/5465#issuecomment-157888325

// usage
const somePromise = new Promise(resolve => setTimeout(resolve, 1000));
const cancelable = makeCancelable(somePromise);

cancelable
  .promise
  .then(() => console.log('resolved'))
  .catch(({isCanceled, ...error}) => console.log('isCanceled', isCanceled));

// Cancel promise
cancelable.cancel();
*/