const device = {
    mobile: `(min-width: 1px)`,
    tablet: `(min-width: 768px)`,
    laptop: `(min-width: 1024px)`,
    desktop: `(min-width: 1366px)`
};

/* usage example:
@media ${device.mobile} {
  background: red;
}

@media ${device.tablet} {
  background: green;
}

@media ${device.laptop} {
  background: blue;
}

@media ${device.desktop} {
  background: darkblue;
}
*/

export default device;