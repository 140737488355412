import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import allActions from '../actions';
import {DeskDto, ServiceDto} from '../interfaces/dto';
import useDesks, {DeskWrapper} from '../services/use-desk-service';

const DeskUpdater: React.FC = () => {
  const timestamp = useSelector(state => state.desks.lastUpdate);
  const isMountedRef = useRef(false);

  const desk: DeskDto = useSelector(state => state.desks.current);
  const {desksResult, loadDesks, setResult} = useDesks();
  const services = useSelector(state => state.services);
  const dispatch = useDispatch();

  useEffect(() => {
    if (desk.id && desksResult.status === 'Ready') {
      const result: DeskWrapper | undefined = desksResult.payload.desks.find((wrapper: DeskWrapper) => wrapper.desk.id === desk.id);
      if (result) {

        // TODO: optimize
        let payload: string[] = result.desk.services.sort((s1, s2) => s1.tag.localeCompare(s2.tag))
          .map((s: ServiceDto) => s.id);

        const currentDeskServices: string[] = desk.services.map((s: ServiceDto) => s.id);

        const subtracted: string[] = currentDeskServices.filter((id: string) => !payload.includes(id));
        const added: string[] = payload.filter((id: string) => !currentDeskServices.includes(id));

        payload = payload.filter((id: string) => services.selected.includes(id))
          .concat(added)
          .filter((id: string) => !subtracted.includes(id));

        dispatch(allActions.desks.setCurrentDesk(result.desk)); // update current desk
        dispatch(allActions.services.selectServices(payload)); // update selected services
        setResult({status: 'Idle'});
      }
    }
  }, [desksResult]); // eslint-disable-line

  useEffect(() => {
    if (isMountedRef.current) {
      loadDesks();
    } else {
      isMountedRef.current = true;
    }
  }, [timestamp]); // eslint-disable-line

  return (
    <></>
  );
};

export default DeskUpdater;