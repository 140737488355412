import {TicketDto} from '../interfaces/dto';

const closeTicket = () => {
  return {
    type: 'CLOSE_TICKET',
  };
};

const setTickets = (list: TicketDto[]) => {
  return {
    type: 'SET_TICKETS',
    payload: list,
  };
};

const takeTicket = (ticket: TicketDto | null) => {
  return {
    type: 'TAKE_TICKET',
    payload: ticket,
  };
};

export default {
  closeTicket, setTickets, takeTicket
};
