import React from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import {DeskDto, ServiceDto} from '../../../interfaces/dto';
import ButtonBase from '../../button/button';
import Overlay from '../../overlay';
import {DialogBody, DialogFooter, DialogHeader, DialogWindow} from '../dialog';
import ServiceButton from './service-button';

interface IProps {
  onCloseClick?(event: Event): void;
  open: boolean;
}

const Content = styled.div`
  max-height: 70vh;
  overflow-y: auto;
  *{
    box-sizing:border-box;
  }
  button{
    box-sizing:border-box;
    padding: 5px;
  }
  h3{
    margin: 0;
    font-size: .8em;
    font-weight: 600;
  }
`;

const Button = styled(ButtonBase)`
  display:flex;
  align-items: center;
`;

const SelectServicesDialog: React.FC<IProps> = (props: React.PropsWithChildren<IProps>) => {
  const desk: DeskDto = useSelector(state => state.desks.current);
  const services = useSelector(state => state.services);

  const close = (event: Event) => {
    props.onCloseClick?.(event);
  };

  return (
    <Overlay open={props.open}>
      <DialogWindow>
        <DialogHeader>Nastavení služeb</DialogHeader>
        <DialogBody>
          <Content>
            <h3>Přiřažené služby</h3>
            {desk && desk.services.map((i: ServiceDto) => (
              <ServiceButton key={i.id} service={i} quantity={services.list.find((s: ServiceDto) => s.id === i.id)?.quantity} />
            ))}
          </Content>
        </DialogBody>
        <DialogFooter style={{justifyContent: 'flex-end'}}>
          <Button disabled={!services.selected || services.selected.length === 0} click={close}>POTVRDIT</Button>
        </DialogFooter>
      </DialogWindow>
    </Overlay>
  );
};

export default SelectServicesDialog;