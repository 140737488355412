import {useState} from 'react';
import {Response} from '../interfaces/api';
import {ConnectionDto, TicketDto} from '../interfaces/dto';
import store from '../store';
import {cancelableFetch, IFetchData} from './service-processer';

const useTicketDetailService = () => {
  const [ticketDetailsResult, setResult] = useState<Response<TicketDto>>({
    status: 'Idle'
  });

  const loadTicketDetails = (ticketId: string) => {
    const bloom: ConnectionDto = store.getState().config.current.bloom;
    const fetchData: IFetchData = {
      uri: `http://${bloom.host}:${bloom.port}/api/1/json/ticket/${ticketId}`,
      method: 'GET',
      parse: 'json'
    };

    return cancelableFetch(fetchData, setResult, (data: any) => data.ticket);
  };

  return {ticketDetailsResult, loadTicketDetails};
};

export default useTicketDetailService;
