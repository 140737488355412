import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import device from '../../device';
import '../../extensions/dateExt';
import useInterval from '../../services/useInterval';
import Row from '../row/row';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #effafd;
  padding: 12px;
  color: #1e272e;

  @media ${device.mobile} {
    font-size: 1em;
  }
  @media ${device.tablet} {
    padding: 24px;
  }
  @media ${device.laptop} {
    font-size: 1.1em;
  }
`;

const Cell = styled.div`
  display: flex;
  align-items: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Icon = styled.div`
  svg{
    padding: 0 .15em 0 .15em;
  }
  &.icon-small {
    font-size: 1.3em;
  }
  &.icon-big{
    font-size: 2em;
  }

  @media ${device.tablet} {
    &.icon-small {
      font-size: 1.6em;
    }
    &.icon-big {
      font-size: 3em;
    }
  }
`;

const Label = styled(Cell)`
  font-size: 2em;
  white-space: nowrap;
  margin-top: -0.1em;

  @media ${device.tablet} {
    font-size: 2.2em;
  }
`;

const TimeLabel = styled.div`
  font-size: .9em;
  font-weight: bold;
`;

const TimeValue = styled.div`
  font-size: .9em;
`;

const Note = styled.div`
  margin: auto;
  font-style: italic;
  text-align: center;

  @media ${device.tablet} {
    margin-top: 20px;
    max-width: 90vw;
  }
`;

const TicketGroup = styled.div`
  margin: auto;
  display: flex;
  align-content: center;
  order:0;
  max-width: 200px;

  @media ${device.tablet} {
    width:auto;
    max-width: initial;
    flex-grow:1;
  }
`;

const ServingTimeGroup = styled.div`
  margin: auto;
  display: flex;
  justify-content: flex-end;
  order:1;
  max-width: 200px;

  @media ${device.tablet} {
    order:3;
    width: auto;
    max-width: initial;
    flex-direction: row-reverse;
  }
`;

const TimesGroup = styled.div`
  display: flex;
  justify-content: space-evenly;
  order:2;
  width: 100%;
  margin: 12px auto 12px auto;

  @media ${device.tablet} {
    width:auto;
    max-width: initial;
    padding-top: 0;
    div {
      padding: 0 10px 0 10px;
    }
  }
`;

const MainRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const InfoBar: React.FC = () => {
  const tickets = useSelector(state => state.tickets);
  const services = useSelector(state => state.services);
  const [servingTime, setServingTime] = useState(0);

  useEffect(() => {
    if (tickets.taken) {
      if (tickets.taken.call_date) {
        const callDate = new Date(tickets.taken.call_date);
        const timeSpan = Math.abs(new Date().getTime() - callDate.getTime());
        setServingTime(Math.round(timeSpan / 1000));
      } else {
        setServingTime(0);
      }
    }
  }, [tickets.taken, setServingTime]);

  // counting logic
  useInterval(() => {
    setServingTime(servingTime + 1);
  }, 1000);

  const getTicketNumber = () => {
    if (!tickets.taken) return '----';

    const service = services.list.find(entry => (entry.id === tickets.taken.service_id));
    const tag = service ? service.tag : ' ';
    return tag + ('' + tickets.taken.number).padStart(3, '0');
  };

  const getTicketDate = () => {
    return tickets.taken?.date ? (new Date(tickets.taken.date)).toLocaleTimeString() : '----';
  };

  const getTicketCallDate = () => {
    return tickets.taken?.call_date ? (new Date(tickets.taken.call_date)).toLocaleTimeString() : '----';
  };

  const getTicketWaitingTime = () => {
    return tickets.taken?.waiting_time ? tickets.taken.waiting_time : '----';
  };

  const formatDuration = (duration: number) => {
    if (!tickets.taken) return '----';
    const date = new Date(duration * 1000);

    return date.toUTCTimeString();
  };

  const isTransfered = () => {
    return (tickets.taken && (tickets.taken.transferred));
  };

  const havePriority = () => {
    return (tickets.taken && (tickets.taken.flags & (1 << 7)) !== 0);
  };

  return (
    <Root>
      <MainRow>
        <TicketGroup>
          <Cell>
            <Icon className={'icon-big'}>
              <FontAwesomeIcon icon={['far', 'file']} />
            </Icon>

            {isTransfered() && (
              <Icon className={'icon-small'}>
                <FontAwesomeIcon icon={['fas', 'exchange-alt']} />
              </Icon>)}

            <Label>{getTicketNumber()}</Label>

            {havePriority() && (
              <Icon className={'icon-small'}>
                <FontAwesomeIcon icon={['fas', 'exclamation-circle']} />
              </Icon>)}
          </Cell>
        </TicketGroup>
        <ServingTimeGroup>
          <Cell>
            <Icon>
              <FontAwesomeIcon icon={['far', 'clock']} size='2x' />
            </Icon>
          </Cell>
          <Column>
            <TimeLabel>Doba obsluhy</TimeLabel>
            <TimeValue>{formatDuration(servingTime)}</TimeValue>
          </Column>
        </ServingTimeGroup>
        <TimesGroup>
          <Column>
            <TimeLabel>Čas příchodu</TimeLabel>
            <TimeValue>{getTicketDate()}</TimeValue>
          </Column>
          <Column>
            <TimeLabel>Doba čekání</TimeLabel>
            <TimeValue>{getTicketWaitingTime()}</TimeValue>
          </Column>
          <Column>
            <TimeLabel>Čas příjetí</TimeLabel>
            <TimeValue>{getTicketCallDate()}</TimeValue>
          </Column>
        </TimesGroup>
      </MainRow>
      <Row>
        <Note>{tickets.taken?.note}</Note>
      </Row>
    </Root>
  );
};

export default InfoBar;