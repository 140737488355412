import React, {useEffect} from 'react';
import {DeskDto} from '../../interfaces/dto';
import useDeskService, {DeskWrapper} from '../../services/use-desk-service';

interface IProps {
  onChange?: (deks: DeskDto) => void;
  size? : number;
}

const DeskSelector: React.FC<IProps> = props => {
  const {desksResult, loadDesks} = useDeskService();

  useEffect(() => {
    if (desksResult.status === 'Idle') {
      loadDesks();
    }
  }, [desksResult, loadDesks]);

  const selectChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (props.onChange) {
      if (desksResult.status === 'Ready') {
        const selected = desksResult.payload.desks.find((i: DeskWrapper) => i.desk.id === event.target.value)?.desk;
        if (selected) {
          props.onChange(selected);
        }
      }
    }
  };

  return (
    <select onChange={selectChanged} size={props.size}>
        {
          desksResult.status === 'Ready' && (desksResult.payload.desks.map(i =>
            <option key={i.desk.id} value={i.desk.id}>{i.desk.description}</option>
          ))
        }
    </select>
  );
};

export default DeskSelector;
