import {isFunction} from 'util';
import {Response} from '../interfaces/api';
import {makeCancelable} from './cancelable-promise';

// TODO: get rid of this, by using of type of the structure used by fetch
export interface IFetchData {
  uri: string;
  method: 'GET' | 'POST';
  headers?: any;
  body?: string | FormData;
  parse?: 'text' | 'json';
  credentials?: 'include' | 'same-origin' | 'omit';
  redirect?: 'follow' | 'manual';
}

const fetchFunc = ({uri, parse, ...rest}: IFetchData) => {
  return fetch(uri, rest);
};

const isSuccess = (response: globalThis.Response): boolean => {
  switch (response.status) {
    case 0:
    case 200:
    case 201:
    case 302: // TODO: 302 is an exceptional state
      return true;

    default:
      return false;
  }
};

const setFailed = (response: globalThis.Response, setResultHook: React.Dispatch<React.SetStateAction<Response<any>>>) => {
  setResultHook({
    status: 'Failed', code: response.status, text: response.statusText
  });
  // tslint:disable-next-line:no-console
  console.error('HTTP Error', response.status, response.statusText, response);
};

const processResult = (data: any, payloadModifier?: (data: any) => any) => {
  let payload = data;
  if (payloadModifier !== undefined && isFunction(payloadModifier)) {
    try {
        payload = payloadModifier(data);
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error('Problem while modifiing payload' + error);
    }
  }
  return payload;
};

export const cancelableFetch = (fetchData, setResult, payloadModifier?) => {
  let success = false;
  setResult({status: 'Loading'});
  const cancelable = makeCancelable(fetchFunc(fetchData));
  cancelable
    .promise
    .then((response: any) => {
      success = isSuccess(response);
      if (!success) {
        setFailed(response, setResult); // TODO: test if it works properly
      }
      switch (fetchData.parse) {
        case 'json': return response.json();
        case 'text': return response.text(); // TODO: is it necessary?
        default: return new Promise((resolve: (value?: unknown) => void) => resolve(response));
      }
    })
    .then((parsedData: any) => {
      return new Promise(
        (resolve: (value?: unknown) => void) => {
          if (success) {
            const payload = processResult(parsedData, payloadModifier);
            setResult({status: 'Ready', payload});
          }
          resolve(parsedData);
        });
      }
    );

  return cancelable;
};
