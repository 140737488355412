import React from 'react';
import Websocket from 'react-websocket';
import {ConnectionDto, TicketDto} from './interfaces/dto';
import {QBus} from './qbus';
import store from './store';

interface IProps {
  userId: string;
  qmpadId: string;
  deskId: string;
  startingTicket: TicketDto | undefined;
  onLogout: () => void;
}

export const {Provider: QbusProvider, Consumer: QBusConsumer} = React.createContext<QBus|null>(null);

const QBusConnection: React.FC<IProps> = props => {
  const wsRef = React.createRef<Websocket>();
  const qbus: QBus = new QBus(wsRef, props.qmpadId, props.userId, props.deskId, props.startingTicket);
  const onOpen = () => { qbus.register(); };
  const onMessage = (message: string) => { qbus.recieve(message); };
  const getApiSocket = () => {
    const bloom: ConnectionDto = store.getState().config.current.bloom;
    return `ws://${bloom.host}:${bloom.port}/qbus`;
  };

  return (
    <>
      <Websocket url={getApiSocket()} onOpen={onOpen} onMessage={onMessage} ref={wsRef} />
      <QbusProvider value={qbus}>
        {props.children}
      </QbusProvider>
    </>
  );
};

export default QBusConnection;
