import {ServiceDto} from '../interfaces/dto';

interface SetDeskId {
  type: 'SET_DESK_ID';
  payload: string;
}

interface SetServices {
  type: 'SET_SERVICES';
  payload: ServiceDto[];
}

interface SelectService {
  type: 'SELECT_SERVICE';
  payload: string;
}

interface SelectServices {
  type: 'SELECT_SERVICES';
  payload: string[];
}

interface UnselectService {
  type: 'UNSELECT_SERVICE';
  payload: string;
}

type ServiceAction = SetDeskId | SetServices | SelectService | SelectServices | UnselectService;

export interface ServicesState {
  list: ServiceDto[];
  selected: null | string[];
  deskId: null | string;
}

const services = (state: ServicesState = {list: [], selected: null, deskId: null}, action: ServiceAction) => {
  switch (action.type) {
    case 'SET_DESK_ID':
      return {...state, deskId: action.payload};

    case 'SET_SERVICES':
      if (!state.selected) {
        state.selected = action.payload.map(i => i.id);
      }
      return {...state, list: action.payload};

    case 'SELECT_SERVICE':
      if (!state.selected) return state;
      return {...state, selected: [...state.selected, action.payload]};

    case 'SELECT_SERVICES':
      return {...state, selected: [...action.payload]};

    case 'UNSELECT_SERVICE':
      if (!state.selected) return state;
      return {...state, selected: state.selected.filter(i => (i !== action.payload))};

    default: return state;
  }
};

export default services;
