import React from 'react';
import {useSelector} from 'react-redux';
import {ConfigDto, PrinterDto} from '../../interfaces/dto';

interface IProps {
  onChange?: (printer: PrinterDto) => void;
  size? : number;
}

const PrinterSelector: React.FC<IProps> = props => {
  const config: ConfigDto | null = useSelector(state => state.config.current); // eslint-disable-line
  /*
  const selectChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (props.onChange && config) {
        const selected = config.printers.find((i: PrinterDto) => i.id === event.target.value);
        if (selected) {
          props.onChange(selected);
        }
    }
  };

  return (
    <select onChange={selectChanged} size={props.size}>
        {
          config && config.printers.map(i => (
            <option key={i.id} value={i.id}>{i.name}</option>
          ))
        }
    </select>
  );
};
*/

  return (
    <></>
  );
};

export default PrinterSelector;
