import {combineReducers} from 'redux';
import config from './config';
import desks from './desks';
import services from './services';
import tickets from './tickets';

const reducers = combineReducers({
  config,
  desks,
  services,
  tickets,
});

export default reducers;
