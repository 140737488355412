import config from './config-actions';
import desks from './desks-actions';
import services from './services-actions';
import tickets from './tickets-actions';

const allActions = {
  config,
  desks,
  services,
  tickets,
};

export default allActions;
