export enum EventType {
  cancelTicket = 'cancel',
  closeTicket = 'close',
  pauseTicket = 'pause',
  takeTicket = 'takeover',
  transferTicket = 'forward',
  enterAllowed = 'enter-allowed',
  enterProhibited = 'enter-prohibited'
}
const eventTypes: string[] = Object.values(EventType);

class EventManager {
  private prefix = 'qm-';

  addListener(eventType: EventType, listener: any);
  addListener(eventType: string, listener: any); // tslint:disable-line:unified-signatures (caused bad intellisence)
  addListener(eventType, listener) {
    if (this.isValidEventType(eventType)) {
      window.addEventListener(this.prefix + eventType, listener);
    } else {
      // tslint:disable-next-line:no-console
      console.error(`Unknown event type ${eventType}. Event listener not created.`);
    }
  }

  removeListener(eventType: EventType, listener: any);
  removeListener(eventType: string, listener: any); // tslint:disable-line:unified-signatures (caused bad intellisence)
  removeListener(eventType, listener) {
    if (this.isValidEventType(eventType)) {
      window.removeEventListener(this.prefix + eventType, listener);
    } else {
      // tslint:disable-next-line:no-console
      console.error(`Unknown event type ${eventType}. Event listener not removed.`);
    }
  }

  isValidEventType(s: string) {
    return eventTypes.includes(s);
  }

  invokeEvent(eventType: EventType) {
    window.dispatchEvent(new Event(this.prefix + eventType));
  }
}

export default new EventManager();