import React from 'react';
import './row.css';

interface IProps {
  className?: string;
}

const Row: React.FC<IProps> = props => {
  const className = (props.className ? props.className + ' ' : '') + 'flex-row';
  return (
    <div className={className}>{props.children}</div>
  );
};

export default Row;
