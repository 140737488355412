import {useState} from 'react';
import {Response} from '../interfaces/api';
import {ConnectionDto, UserDto} from '../interfaces/dto';
import store from '../store';
import {cancelableFetch, IFetchData} from './service-processer';

export interface UsersWrapper {
  user: UserDto;
}

export interface Users {
  success: boolean;
  users: UsersWrapper[];
}

const useUsersService = () => {
  const [usersResult, setLoadUsersResult] = useState<Response<Users>>({
    status: 'Idle'
  });

  const loadUsers = () => {
    const bloom: ConnectionDto = store.getState().config.current.bloom;
    const fetchData: IFetchData = {
      uri: `http://${bloom.host}:${bloom.port}/api/1/json/users`,
      method: 'GET',
      parse: 'json'
    };

    return cancelableFetch(fetchData, setLoadUsersResult);
  };

  return {usersResult, loadUsers};
};

export default useUsersService;
